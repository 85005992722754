@import '../../variables.scss';

.radio-button-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .ry-label + & {
    margin-top: 0.5rem;
  }
}

.radio-button {
  position: relative;

  // instead of display: none, hiding radio input behind label for a11y to
  // maintain keyboard controls
  &__control {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: -1;

    &:focus + .radio-button__label {
      outline: 0;
      box-shadow: 0 0 0 1px #fff, 0 0 0 4px #b2d6e8;
    }
  }

  &__label {
    display: inline-block;
    padding: 0.4375rem 1rem;
    font-weight: bold;
    font-size: 0.875rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    background-color: #fff;
    border: 1px solid map-get($gray-steps, 200);
    border-radius: 4px;
    cursor: pointer;
  }

  & + & {
    margin-left: 0.5rem;
  }

  &--active &__label {
    color: #fff;
    background-color: $ryan-navy;
    border: 1px solid $ryan-navy;
  }
}
