@import '../../../variables.scss';

.file-directory-search-result {
  .bs {
    line-height: 1rem;

    b b {
      display: inline;
      color: $action-teal;
    }

    &:hover b b {
      color: inherit;
    }
  }

  &__folder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 1rem;

    &--archived {
      button {
        font-style: italic;
      }
    }
  }

  &__tooltip-target {
    display: inline;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    text-decoration: underline !important;
  }

  &__tooltip {
    .ry-label {
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 0;
      padding: 0;

      &:last-child {
        font-weight: 600;
      }

      &:not(:first-child) {
        position: relative;
        padding-left: 1.5rem;
      }
    }

    .ry-icon {
      position: absolute;
      top: -2px;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
