@import '../../variables';

.manage-pending-new-user-table {
  .ry-table__td {
    vertical-align: middle;
  }

  .ry-icon {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
  }
}
