.role-permissions-legend {
  position: relative;
  pointer-events: none;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    position: relative;
    padding: 0.5rem 0 0.5rem 3rem;

    .ry-h3 {
      margin-bottom: 0;
    }

    .ry-checkbox,
    .checklocks {
      top: -0.5rem;
      left: -3.5rem;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: '';
  }
}
