@import '../../variables.scss';

.attachments {
  display: block;
  max-width: 40rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;

  &__well {
    padding: 0.5rem;
    background-color: map-get($gray-steps, 50);
  }

  &__list {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__item {
    margin: 0;
    padding: 0;

    td {
      vertical-align: top;
    }

    &-icon {
      padding: 0.5rem;
    }

    &-name {
      width: 100%;
      padding: 0.5rem 0;
      word-break: break-word;
      overflow-wrap: break-word;
    }

    &-controls {
      white-space: nowrap;

      .ry-button--icon-only.ry-button--text {
        padding: 0.5rem;
        vertical-align: top;
      }
    }

    &-delete {
      color: inherit;

      &:hover {
        color: $action-teal;
      }
    }
    &-visibility-tooltip {
      padding: 0.5rem;
      vertical-align: top;
      background: none;
      border: none;
      border-radius: 4px;
      cursor: help;
    }
  }

  .ry-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
