.user-conversion-modal {
  text-align: center;

  @media (min-width: 576px) {
    max-width: 480px;
    margin: auto;
  }

  .ry-dropdown {
    margin-top: 2rem;
    text-align: left;
  }
}
