.reporting-card {
  &__content {
    display: flex;
    p {
      padding-left: 1rem;
    }
    .ry-icon {
      flex-shrink: 0;
      width: 3rem;
      height: 3rem;
    }
  }
}
