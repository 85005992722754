@import '../../variables';

@mixin dismiss-button {
  padding: 0;
  color: #afb1bc;
  background: none;
  border: none;
  cursor: pointer;

  .ry-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover:not(:disabled) {
    color: $action-teal;
  }
}

.notification-feed {
  &__day-group {
    margin-bottom: 1rem;

    // the container
    &-label {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      color: #afb1bc;

      // the label
      &-text {
        z-index: 1;
        padding-right: 1em;
        font-weight: 600;
        font-size: 0.875rem;
        text-transform: uppercase;
        background-color: inherit;
      }

      // the horizontal line
      &-line {
        flex: 1 1 auto;
        height: 1px;
        background-color: #d8d8d8;
        content: '';
      }
    }

    &-dismiss {
      @include dismiss-button;

      margin-left: 0.5rem;
    }
  }

  &__activity {
    margin-bottom: 0.5rem;

    .activity[role='link'] {
      transition: box-shadow 150ms ease-out;

      &:hover {
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }

    &-dismiss {
      display: none;
    }
  }

  //
  // Skelton
  //

  &--skeleton {
    width: 100%;

    .notification-feed__activity {
      width: 100%;

      .ry-skeleton:first-child {
        position: absolute;
        top: 1rem;
        left: 0.625rem;
        width: 1rem;
        height: 1rem;
      }

      .ry-skeleton:last-child {
        width: 60%;
        margin-top: 0.5rem;
      }
    }
  }

  // At the medium breakpoint, we have dismiss buttons (x)
  @media (min-width: 768px) {
    &__activity {
      display: flex;

      .activity {
        flex: 1 1 auto;
        min-width: 0;
      }

      &-dismiss {
        @include dismiss-button;

        display: block;
        flex: 0 0 2rem;
        text-align: right;
        visibility: hidden;
      }

      &:hover &-dismiss {
        visibility: visible;
      }
    }
  }
}
