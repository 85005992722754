@import '../../variables';

@mixin task-dataviz-variants() {
  $stripe-alt-color: #bededf;
  &--completed {
    background-color: $action-teal;
  }

  &--inprogress {
    background: repeating-linear-gradient(
      -45deg,
      $action-teal,
      $action-teal 1px,
      $stripe-alt-color 2px,
      $stripe-alt-color 3px
    );
  }

  &--todo {
    background-color: #e8e9ed;
  }
}

.task-dataviz {
  position: relative;

  .ry-number-1 {
    line-height: 3rem;
  }

  *.bar-chart {
    height: 0.5rem;
    margin: 1.5rem 0;
    border-radius: 0.5rem;
  }

  &__tooltip {
    position: absolute;
    top: 1.4rem;
    right: 1rem;
    cursor: pointer;

    .ry-icon {
      width: 1.8rem;
      height: 1.8rem;
    }

    &:hover {
      color: $action-teal;
    }
  }

  &__overall-total {
    padding-left: 1rem;
    border-right: 1px solid map-get($gray-steps, 200);

    h1 {
      margin-bottom: 0;
    }

    label {
      color: #626374;
      font-weight: 550;
      font-size: 0.75rem;
      line-height: 1.25rem;
      text-transform: uppercase;
    }
  }

  &__legend {
    font-size: 0.875rem;
    line-height: 1.5rem;

    strong {
      margin-right: 0.25rem;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    overflow: hidden;
    vertical-align: baseline;
    border-radius: 50%;

    @include task-dataviz-variants();
  }

  &__buttons {
    display: flex;

    button + button {
      flex-shrink: 0;
      margin-left: 1rem;
    }
  }
}
