.reassign-task-modal {
  p {
    text-align: left;
  }

  &__assigned-to {
    margin: 1rem 0;
    padding: 1rem;
    background: #f8f8fa;
  }
}
