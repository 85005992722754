@import '../../variables.scss';

@mixin button-reset {
  width: 48px;
  min-width: auto;
  max-width: none;
  padding: 0.5rem;
  color: #a3a4b2;
  font-size: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  cursor: pointer;
  appearance: none;
  &:disabled {
    cursor: default;
  }
}

.hide {
  display: none;
}

.modal-header {
  flex-grow: 0;
  flex-shrink: 0;
  width: 138px;
  height: 16px;
  color: rgb(98, 99, 116);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  text-transform: uppercase;
}

#clear-all {
  height: 16px;
  color: #0a8287;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  background-color: transparent;
  border: none;
}

.filter-select-all {
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
}

#chevron {
  background: none;
  border: none;

  svg {
    width: 20px;
    height: '100%';
  }
}

.data-type-section {
  min-height: 25px;

  p {
    margin: 0;
    padding: 0;
  }

  .section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    color: rgb(98, 99, 116);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    text-transform: uppercase;

    button {
      cursor: pointer;
    }
  }
}

.add-items-header {
  margin: 0;
  padding: 0;
  color: rgb(62, 64, 80);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

#collapse-section {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  padding: 0;
  background: none;
  border: none;

  svg {
    width: 25px;
    height: 25px;
  }
}

.total-selected-header {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d2d3dc;

  button {
    cursor: pointer;
  }
}

.filter-selection-section {
  flex: 1 1 auto;
  margin-top: 20px;
  padding-right: 10px;
  padding-bottom: 14px;
  padding-left: 10px;
  background-color: #f8f8fa;
  border-top: 1px solid #d2d3dc;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      height: 57px;
    }

    li:hover {
      background-color: #f8f8fa;
    }
  }

  &__additional-filters {
    padding-top: 0.5rem;

    &__label {
      font-size: 14px;
    }
  }
}

.add-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 11px 0;
}

.filter-select-container {
  position: relative;
  margin-top: 0.1rem;

  &__dropdown {
    max-height: 12rem;
    padding: 1rem;
    overflow-y: auto;
    background-color: $white;

    ul {
      background: $white;
    }
  }

  &__selection-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    background-color: $white;
    border: 1px solid map-get($teal-steps, 400);
    border-radius: 4px;
    cursor: pointer;

    &__label {
      padding: 0.7rem 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__caret {
      padding: 0 0.5rem;
      color: $checkbox--disabled__color;
      background: none;
      border: 0;
      cursor: pointer;
      appearance: none;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    &--placeholder {
      color: map-get($gray-steps, 400);
    }
  }
}

.filter-option {
  display: flex;
  flex-direction: row;
  align-items: center;

  h4 {
    font-weight: normal;
  }
}

.selected-filters-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 25px;
  margin-left: 5px;
  padding-top: 10px;
}

.selected-filter-pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 24px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  color: #fff;
  background-color: #0e247e;
  border-radius: 12px;

  p {
    padding: 2px 5px;
    font-weight: bold;
    font-size: 14px;
  }

  .delete-filter {
    width: 17px;
    height: 17px;
    background-color: #fff;
    border-radius: 10px;
  }
}

.selections {
  min-height: 100px;
  max-height: 275px;
  padding-top: 22px;
  overflow-y: auto;
}

.section-divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  .line {
    width: '45%';
  }

  p {
    margin: 0;
    padding: 0;
    color: rgb(99, 99, 99);
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
  }
}
