@import '../../../../../mixins';
@import '../../../../../variables';

.edit-data-request-form {
  .ry-multiselect {
    .ry-multiselect__container {
      .ry-multiselect__container__control {
        min-height: 3rem;
      }
    }
  }

  .dss-file-upload {
    padding: 1rem;
    border: 1px solid map-get($teal-steps, 400);
    border-radius: 4px;
  }

  &__multiselect-field {
    &--error {
      .ry-multiselect__container__control {
        border-color: $error-red;
      }
    }
  }

  &__textarea-field {
    .ry-textarea__control {
      min-height: 8.5rem;
    }
  }

  &__date-ranges {
    &__label {
      .ry-label {
        color: map-get($gray-steps, 600);
      }

      margin-bottom: 2rem;
    }

    margin-bottom: 1.5rem;
    padding: 1rem 0 0.5rem 0;
    border-bottom: 1px solid map-get($gray-steps, 200);
  }

  &__attach-buttons-wrapper {
    .radio-button-group {
      .radio-button {
        & + .radio-button {
          margin-left: 30px;
        }
        &__label {
          padding: 0;
          color: $field-control--disabled__color;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0;
          text-transform: none;
          border: 0;
          outline: 0;
          box-shadow: none;
          &::before {
            position: relative;
            z-index: 2;
            display: inline-block;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            margin: 12px;
            vertical-align: middle;
            border: 1px solid $ryan-navy;
            border-radius: 50%;
            cursor: pointer;
            content: '';
          }
        }
        &--active {
          &::after {
            position: absolute;
            top: 18px;
            left: 18px;
            z-index: 2;
            display: inline-block;
            width: 12px;
            height: 12px;
            background: $ryan-navy;
            border-radius: 50%;
            content: '';
          }
          .radio-button__label {
            background: transparent;
          }
        }
      }
    }
  }
}
