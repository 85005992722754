.contact-information-skeleton {
  .sk-field {
    margin-bottom: 2rem;
  }
  &__avatar-area {
    display: flex;
    padding-bottom: 2rem;
    .sk-btn-lg {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
    }
  }
  &__address-area {
    padding-top: 4rem;
  }
  .space {
    height: 3rem;
  }
}
