.savings-summary {
  h1 .pill {
    position: relative;
    top: -2px;
    height: 2rem;
    margin-left: 0.5rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 2rem;
    vertical-align: middle;
  }

  h2 {
    margin: 0 0 0.5rem 0;
  }

  &__screen-too-small {
    margin: 4rem 0 2rem 0;
    text-align: center;

    img {
      margin-bottom: 1rem;
    }

    p {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
