@import '../../../variables.scss';

.account-switcher-desktop {
  position: relative;
  display: inline-block;
  max-width: 100%;
  text-align: left;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__toggle {
    position: relative;
    max-width: 100%;
    padding-right: 3rem !important;
    overflow: hidden;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: none;

    &:focus {
      position: relative;
      z-index: 1;
    }

    // Chevron, ExecutiveView lock
    > .ry-icon,
    &-tooltip {
      position: absolute;
      right: 1rem;
      transform: none;
    }

    &-inactive {
      color: $ryan-gray;

      .ry-icon {
        padding: 0.2rem;
      }
    }
  }

  &--open {
    z-index: 999; // 1 shy of Header
    -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.24));
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.24));

    .account-switcher-desktop__toggle {
      background-color: white;
      border-radius: 4px 4px 0 0;
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 52rem;
    max-width: calc(100vw - 2rem);
    height: 26rem;
    padding: 0 0 0 18rem;
    overflow: hidden;
    background-color: white;
    border-radius: 4px 0 4px 4px;

    // iphone x viewport
    @supports (padding: max(0)) {
      max-width: calc(100vw - max(6rem, env(safe-area-inset-left)));
    }
  }

  &__recents,
  &__filters {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 18rem;

    &-header {
      position: relative;
      padding: 2.5rem 1rem 1rem 1rem;

      span {
        font-weight: 700;
        font-size: 0.875rem;
        font-family: inherit;
        line-height: 1.5rem;
        letter-spacing: 0.14285714285em; // 2px from 14px
        text-transform: uppercase;
      }

      // Reset
      button {
        display: inline;
        float: right;
        margin: 0;
        padding: 0;
        color: #0a8287;
        font-weight: inherit;
        font-size: 0.75rem;
        font-family: inherit;
        line-height: inherit;
        text-align: inherit;
        text-decoration: none;
        background: none;
        border: none;
        cursor: pointer;
        appearance: none;

        &:hover {
          color: #00508a;
          text-decoration: underline;
        }

        &:active {
          color: #003865;
          text-decoration: underline;
        }
      }
    }
  }

  &__recents {
    color: white;
    background-color: #0a8287;

    &-header {
      &::after {
        position: absolute;
        right: 1rem;
        bottom: 0;
        left: 0;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.5);
        content: '';
      }
    }

    .ry-link {
      display: block;
      width: 100%;
      padding: 1rem;
      overflow: hidden;
      color: #fff;
      white-space: nowrap;
      text-align: left;
      text-decoration: none;
      text-overflow: ellipsis;

      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  &__filters {
    background-color: #f8f8fa;
    border-right: 1px solid #a3a4b2;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;

    &--open {
      transform: translateX(0);
    }

    &-tab {
      position: relative;

      &-button {
        // block
        display: block;
        width: 100%;
        padding: 1rem;
        overflow: hidden;
        line-height: inherit;

        // text-wrap
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;

        // reset
        background: none;
        border: none;
        cursor: pointer;
        appearance: none;

        & + .ry-icon {
          position: absolute;
          top: 0.75rem;
          right: 0.75rem;
          width: 2rem;
          height: 2rem;
          pointer-events: none;
        }
      }

      // top border
      &::before {
        position: absolute;
        top: 0;
        right: 1rem;
        left: 0;
        height: 1px;
        background-color: #d2d3dc;
        content: '';
      }

      &--active &-button {
        padding-right: 2rem;
        color: $ryan-navy;
        font-weight: 600;
      }

      // blue notch
      &--active::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0.25rem;
        background-color: $ryan-navy;
        content: '';
      }
    }

    &-buttons {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1rem;
    }

    &-switch {
      padding: 0.5rem 1rem 1rem 2rem;
      font-size: 0.875rem;

      .info-tooltip {
        margin-left: 0.5rem;
      }

      .ry-switch {
        margin-left: 1rem;
        vertical-align: middle;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
      display: flex;
      flex: 0 0 auto;
      padding: 1rem;
      border-bottom: 1px solid #a3a4b2;

      .search-input {
        flex: 1 1 auto;
      }
    }

    &-scroll {
      flex: 1 1 auto;
      overflow: scroll;
    }
  }

  &__empty {
    padding: 1rem;
    font-style: italic;
  }
}
