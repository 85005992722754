@import '~@ryan/components/src/styles/utils/typography';
@import '../../variables';

.terms-of-use {
  // make sure all content aligns with max width of paragraphs
  max-width: 35rem;

  // Make sure buttons at bottom are not cut off on any size screen
  padding-bottom: 4.5rem;

  &__terms {
    height: 25rem;
    padding: 1rem;
    overflow-y: scroll;
    border: solid 1px map-get($teal-steps, 200);
    border-radius: 0.25rem;
  }

  .ry-checkbox {
    margin: 1rem 0;
  }

  a {
    @include ry-link;
  }

  h1 {
    @include ry-h1;

    &:first-child {
      margin-top: 2rem;
    }
  }

  h2 {
    @include ry-h2;

    margin-top: 2.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    @include ry-h3;
  }

  li {
    margin-bottom: 0.5rem;
  }

  .document-links a {
    display: inline-block;
  }

  .page-help-form__assistance {
    margin-bottom: 0;
  }

  @media (min-width: 601px) {
    .ry-button-group {
      flex-direction: row;

      .ry-button {
        order: 1;
      }

      .ry-button--primary {
        order: 2;
      }
    }
  }
}
