.project-details-modal {
  &__title {
    display: block;
    text-transform: uppercase;
  }

  &__dates-container__currency-option {
    .ry-dropdown__label {
      margin-bottom: 0;

      button {
        position: relative;
        top: -0.125rem;
        padding-left: 0.5rem;

        .ry-icon {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  &__button-group {
    width: 90%;
    margin: 0 auto;
  }

  .ry-textarea {
    margin-bottom: 0;
  }

  .ry-button-group {
    margin-top: 0;
  }
}
