@import '../../variables';

.status {
  display: inline-block;
  line-height: 1.5rem;
  white-space: nowrap;
  vertical-align: top;

  &__icon {
    width: 1rem;
    height: 1rem;
    margin: 0.25rem;
    color: $ryan-navy;
    vertical-align: top;
    fill: currentColor;
  }

  &--lg {
    font-size: 1rem;

    .status__icon {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 0.5rem 0 0;
    }
  }
}
