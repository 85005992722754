.task-modal {
  width: 640px;

  .file-attach > .dss-file-upload .ry-file-upload {
    padding: 1.25rem;
    border: 1px solid #aab9bf;
    border-radius: 4px;
  }

  &__account-select {
    .ry-h2 {
      margin-bottom: 1.5rem;
      padding-top: 0.5rem;
      font-weight: 600;
      text-align: left;
    }

    &__account {
      margin-bottom: 2.813rem;
    }

    &__action {
      width: 100%;
      text-align: center;
    }

    &__project {
      margin-bottom: 4.563rem;
    }
  }

  &__create-task-header {
    .ry-h2 {
      margin-bottom: 2rem;
      padding-top: 0.5rem;
      font-weight: 600;
      text-align: left;
    }

    &__info-well {
      margin-bottom: 2rem;
      padding: 2rem 1rem;
      background-color: #f8f8fa;

      p {
        font-weight: 600;
        text-align: left;
      }

      .task-modal__info-well--project {
        margin-top: 0.5rem;
        color: #626374;
      }
    }
  }
}
