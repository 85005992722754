@import '../../../variables.scss';

.savings-summary {
  .ry-table + .ry-table {
    margin-top: 2.5rem;
  }

  .ry-table__header-title .info-tooltip {
    vertical-align: middle;
  }

  &__no-entries {
    padding: 1rem 0;
    font-size: 1rem;

    &-small {
      display: flex;

      &-image {
        flex: 0 0 auto;
        align-self: flex-start;
      }

      &-content {
        flex: 1 1 auto;
        padding: 1rem;
      }

      &-label {
        margin-bottom: 0.5rem;
        font-weight: 600;
      }
    }
  }

  &__details {
    padding: 1rem 0;

    &-header {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
    }

    &-notes {
      padding: 0.25rem 1rem;
      // margin: 1rem 0 0 0;
      white-space: normal;
      border-left: 4px solid #d8d8d7;

      &-header {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      p {
        margin: 0;
      }
    }

    &-table-wrapper {
      display: table;
    }

    &-table {
      width: 100%;
      max-width: 61rem;
      margin: 0.5rem 0;
      font-size: 0.75rem;
      table-layout: fixed;
      border-collapse: collapse;

      th {
        padding: 0.5rem 0;
        font-size: 0.875rem;
      }

      td {
        padding: 0;
        border-bottom: 1px solid #d8d8d7;
      }

      th,
      td {
        &:not(:first-child) {
          padding-left: 0.5rem;
          text-align: right;
        }
      }

      .ry-skeleton {
        display: inline-block;
        width: 100%;
        max-width: 5rem;
        margin: 0.25rem 0;
        vertical-align: middle;
      }
    }

    &-totals {
      td:first-child {
        font-weight: 700;
      }
    }

    &-actions {
      display: flex;
      align-items: flex-start;

      .ry-button {
        flex: 0 0 auto;
      }

      &-spacer {
        flex: 1 1 auto;
      }
    }

    h2 {
      margin: 0;
    }

    .labeled-list {
      display: flex;
      font-size: 1rem;

      li {
        min-width: 8rem;
        margin: 0 0.5rem;
      }
    }
  }

  &__totals {
    margin-top: 2.5rem;

    .ry-label {
      margin: 0;
    }

    .ry-card__content {
      padding-top: 0;
    }

    &-as-of {
      margin-bottom: 1.5rem;
      font-size: 0.875rem;
      line-height: 1rem;
    }

    &-line {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .ry-number-2 {
        line-height: 2rem;
      }

      .info-tooltip {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }

    &-tooltip-target {
      padding: 0;
      color: $action-teal;
      font-size: 1rem;
      line-height: 2rem;
      background: none;
      border: none;
      cursor: pointer;
      appearance: none;

      &:hover,
      &:active,
      &:focus {
        color: #004247;
      }

      .ry-icon {
        width: 2rem;
        height: 2rem;
        vertical-align: top;
      }
    }
  }

  &__historical-empty {
    margin: 1.5rem 0 0 0;
    background-color: #f8f8fa;
    border: 1px solid #d2d3dc;
    border-radius: 4px;

    &-toggle {
      position: relative;
      display: block;
      width: 100%;
      padding: 1rem;
      padding: 1rem 1rem 1rem 3.5rem;
      font-weight: 600;
      text-align: left;
      background: none;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      appearance: none;

      .ry-icon {
        position: absolute;
        top: 0.75rem;
        left: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &-content {
      padding: 0 1rem 1rem 3.5rem;

      p {
        margin: 0;
      }

      .col-md-4 {
        text-align: right;
      }
    }

    &-button {
      @media (max-width: 767px) {
        margin-top: 1rem;
      }

      .ry-button {
        white-space: unset;
      }
    }
  }

  &__footer {
    @media (min-width: 376px) {
      padding: 1.5rem 2.5rem;
    }

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: initial;
    margin: 0;
    padding: 1rem;
    font-size: 0.875rem;
    background-color: map-get($gray-steps, 50);
  }
}
