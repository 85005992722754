.timelines-card {
  .ry-card__content {
    padding: 1rem 0 0 0;
  }

  &__view-all {
    padding: 1rem 0 2rem;
    text-align: center;
  }
}
