.welcome-email-card {
  button {
    display: flex;
    align-items: center;
    margin-bottom: 0.875rem;
    padding: 0;
    letter-spacing: 0;
    text-transform: initial;
    border: none;
  }

  .ry-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  &__message {
    margin: 0 0 1.25rem;

    &--short {
      margin-bottom: 1rem;
    }

    &__expired-status {
      color: #cc2936;
    }
  }
}
