@import '../../mixins.scss';
@import '../../variables.scss';

.user-info-card {
  position: relative;

  .additional-options {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    margin: initial;
    color: map-get($gray-steps, 600);
    vertical-align: initial;
  }

  .ry-card__content {
    & > ul {
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        display: flex;
        align-items: center;
      }

      li {
        margin-bottom: 0.5rem;
      }

      .ry-icon {
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }
    }
  }
  &__newline {
    white-space: pre-line;
  }

  &__edit {
    margin-top: 1rem;
  }

  &__profile-summary {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    @include ry-avatar-scaled(2) {
      // HACK: Unused. No empty blocks allowed.
      flex: 0 0 auto;
    }

    &__description {
      padding-left: 1rem;

      h4 {
        margin: 0;
        font-weight: initial;
      }

      &__name {
        margin: 0;
        font-weight: 600;
        font-size: 1rem;
      }
    }
  }
}
