.edit-user-projects {
  &__table {
    // reset Checkbox to fit in the table
    .ry-checkbox {
      display: inline-block;
      margin-top: -0.75rem;
      margin-bottom: -0.75rem;
      vertical-align: top;

      label {
        padding-right: 0;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: flex-start;
    margin: 2rem 0 1rem;
  }
}
