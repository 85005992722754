@import '~@ryan/components/src/styles/variables';

@import '../../../../../../variables.scss';

.task-status {
  width: 42%;

  .well.task-status--past-due {
    background-color: $error-background-red;

    .status,
    .status__icon {
      color: $error-red;
    }
  }

  &__details {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid map-get($gray-steps, 200);

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        width: 50%;
        padding-bottom: 1rem;

        button.ry-button {
          margin: 0 0.5rem;
          padding: 0;
          font-weight: 400;
          font-size: 1rem;
          letter-spacing: normal;
          text-transform: capitalize;

          &:first-of-type {
            margin-left: 0;
          }
        }

        label {
          display: block;
          font-weight: 600;
          font-size: 0.75rem;
          line-height: 1rem;
          text-transform: uppercase;
        }
      }
    }
  }

  &__linked-to {
    margin-top: 8px;
    font-size: 14px;

    a {
      margin-left: 4px;
      color: #0a8287;
      text-decoration: none;
    }

    .ry-icon {
      width: 24px;
      height: 24px;
    }
  }

  &__link-milestone {
    padding-left: 0.5rem;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: initial;
  }
}
