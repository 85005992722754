@import '../../../variables.scss';
@import '../../../mixins';

.multiselect-daterange__outer-container {
  display: flex;
  flex-direction: column;

  &__error {
    margin-bottom: 1rem;
    color: $red;
    font-size: 0.75rem;

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
      color: $red;
    }
  }

  &__field {
    display: flex;
    align-items: center;

    &__inner-container {
      width: 100%;

      &__inputs-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &--errors {
          border-bottom: 1px solid $red;
        }

        &__input {
          display: flex;
          flex-direction: column;
          width: 100%;

          .ry-datepicker {
            margin-bottom: 2rem;

            .ry-datepicker__helper-text {
              color: transparent;
            }
          }

          &:first-of-type {
            margin-right: 2rem;
          }

          &:last-of-type {
            margin-left: 2rem;
          }
        }

        &__mdash {
          margin-bottom: 1rem;

          &--errors {
            margin-bottom: 2.5rem;
          }

          &--error {
            margin-bottom: 2.5rem;
          }
        }
      }
    }

    &__submit {
      margin-bottom: 1rem;
      padding: 0;
      color: $red !important;

      svg {
        width: 2rem;
        height: 2rem;
        color: $red;
      }

      &--disabled {
        color: $button--text--disabled__color;
        pointer-events: none;
      }

      &--invisible {
        display: none;
      }

      &--errors {
        margin-bottom: 2.5rem;
      }

      &--error {
        margin-bottom: 2.5rem;
      }

      &:hover {
        color: $ryan-gray;
        background-color: transparent;
      }
    }
  }
}
