@import '../../../variables';

.project-team-table {
  // Card Layout
  &__card-view {
    &-header {
      display: flex;
      align-items: center;
      margin: 1rem 0;
      white-space: nowrap;

      h2 {
        margin: 0;
      }

      .project-team-table__actions {
        padding-left: 1rem;
      }
    }

    &-content {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(3, 1fr);

      .ry-card {
        margin: 0;
      }

      // Tablet
      @media (max-width: 1023px) {
        grid-template-columns: repeat(2, 1fr);
      }

      // Mobile
      @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  // Table actions (Add Ryan Team Members, Manage Team)
  &__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    .ry-toggles {
      flex: auto;
    }

    .ry-button {
      margin: 0;
    }
  }

  // user row - email icon
  &__user-email {
    display: inline-block;
    margin: -0.75rem 0;
    padding: 0.5rem;
    color: inherit;
    line-height: 1.5rem;
    vertical-align: middle;
    border-radius: 4px;

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      color: $action-teal;
    }
  }

  .additional-options {
    margin: -0.75rem 0;
    vertical-align: middle;
  }

  .confirmation-modal {
    max-width: 480px;
  }

  .ry-text-input {
    width: 18rem;
  }

  .ry-table__header-spacer {
    display: none;
  }

  .ry-table__header-actions {
    display: flex;
    padding-left: 1rem;
    text-align: left;
  }

  // Tablet
  @media (max-width: 1100px) {
    .ry-table__header,
    .project-team-table__card-view-header {
      display: block;

      .ry-table__header-actions,
      .project-team-table__actions {
        padding: 0;
      }

      .project-team-table__actions {
        padding-top: 1rem;
      }
    }

    .search-input {
      width: 100%;
      padding-top: 1rem;

      .ry-text-input {
        width: 100%;
      }
    }
  }

  // Mobile
  @media (max-width: 600px) {
    .ry-toggles {
      margin-bottom: 1rem;
    }

    .project-team-table__actions .ry-button {
      display: block;
      width: 100%;
      margin: 0;
      text-align: left;
    }
  }
}
