@import '../../../../mixins.scss';
@import '../../../../variables.scss';

.assign-data-upload-modal {
  width: 640px;
  min-width: auto;
  max-width: none;

  .ry-message {
    margin-right: auto;
    margin-left: auto;
  }

  .ry-textarea {
    margin-bottom: 1.5rem;

    &__control {
      height: 9rem;
    }
  }

  &__form {
    margin: 3rem 0 3.5rem 0;
  }

  .dss-file-upload {
    flex: 0 1 400px;
    padding: 1rem 2rem;
    border: 1px solid map-get($gray-steps, 300);
    border-radius: 8px;
  }

  .manage-attachments {
    margin: 0;

    .file-attach {
      margin: 0;
    }
  }

  &__buttons {
    .ry-button + .ry-button {
      margin-left: 1rem;
    }
  }

  @media (max-width: 899px) {
    width: auto;

    &__form {
      display: block;
    }

    .dss-file-upload {
      margin: 0 0 1rem;
    }

    &__buttons {
      text-align: center;
    }
  }
}
