.data-request-drawer {
  .ry-h3 {
    margin-bottom: 0;
  }

  .ry-tabs {
    margin-top: 1rem;
  }

  &__data-request-type-name {
    margin-top: 0;
  }
}
