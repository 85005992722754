.recent-activity-card {
  position: relative;

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    @media (max-width: 375px) {
      .ry-button {
        flex: 1 1 auto;
      }
    }
  }
}
