.update-savings-bucket-modal {
  @media (min-width: 0) {
    width: 40rem;
    min-width: auto;
    max-width: none;
  }

  form > section {
    display: flex;
    align-items: flex-start;
    margin-top: 1.5rem;
  }
}
