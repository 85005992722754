.manage-team-roles {
  &__actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2rem;

    .ry-dropdown {
      width: 22.75rem;
    }
  }
}
