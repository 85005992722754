@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.folder-delete-modal {
  &__loading-approval {
    text-align: center;

    .ry-icon {
      width: 2rem;
      height: 2rem;
      color: gray;
      animation: loadingSpinner linear 1s infinite;
    }
  }
}
