@import '../../variables.scss';

.info-tooltip {
  margin: 0;
  padding: 0;
  color: inherit;
  vertical-align: top;
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  appearance: none;

  .ry-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover,
  &:focus {
    color: $action-teal;

    .ry-table th &,
    .savings-summary-edit__table th & {
      .ry-icon {
        color: #01e6aa !important;
      }
    }
  }
}
