@import '../../variables.scss';

.file-directory {
  margin-bottom: 2rem;

  .ry-table {
    &__tr-group-header {
      padding: 0.5rem 3.5rem;
      color: $ryan-navy;
      font-size: 0.75rem;
      text-transform: uppercase;
      background-color: $filters-grey;

      &--sticky {
        position: sticky;
        top: 11rem;
        z-index: 500;
      }
    }

    &--overflowing {
      .ry-table__tr-group-header--sticky {
        position: static;
      }
    }
  }

  &__archive-restore-succes-toast {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;

    button.ry-button {
      padding: 0;
      text-align: left;
    }
  }

  //
  // Headers
  //

  &__header,
  &__path {
    border-bottom: 1px solid #d8d8d8;
  }

  &__header {
    padding: 0.75rem 0;

    h2 {
      margin: 0 0 0.5rem 0;
    }

    @media (max-width: 767px) {
      .ry-button {
        margin: 0 0.5rem 0.5rem 0;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin: 0;
      }
    }
  }

  &__path {
    padding: 0.75rem 0.5rem;
    line-height: 2rem;

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.25rem 0.5rem 0.25rem 0;
      color: inherit;
      // vertical-align: middle;
    }

    .ry-skeleton {
      display: inline-block;
      width: 10rem;
      vertical-align: middle;
    }
  }

  &__selection-header {
    padding: 0.5rem 0 0.5rem 0.75rem;

    &-title {
      margin: 0 0.5rem 0 0;
      padding: 0.5rem 0;
      white-space: nowrap;
    }

    &-actions .ry-button {
      margin: 0.25rem 0.25rem 0.25rem 0;
    }

    @media (min-width: 768px) {
      display: flex;
      align-items: center;

      &-actions {
        flex: 1 1 auto;
        text-align: right;

        .ry-button {
          margin: 0;
        }
      }
    }
  }

  //
  // Rows
  //

  &__table {
    // tighten padding on file type column
    // there is a filter icon here, but no text
    .ry-table__th:nth-child(2) .ry-table__th-el:first-child {
      padding: 0 0 0 0.25rem;
    }

    button.ry-link {
      padding: 0 1em 0 0;
      color: inherit;
    }

    td {
      position: relative;
      white-space: nowrap;
      vertical-align: inherit;

      // all columns should not wrap, EXCEPT for file/folder name
      &:nth-child(3) {
        white-space: inherit;
      }

      .locked-file-name {
        display: flex;
        align-items: center;

        &__tooltip {
          position: absolute;
          right: 1rem;
          width: 1.5rem;
          height: 1.5rem;
          cursor: pointer;
        }
      }
    }

    // shrink pills to look like circles, because who likes consistency?

    .broken {
      .pill {
        position: relative;
        padding: 0 0.5rem;
      }

      .pill::after {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 25px;
        border-top: 1px solid #0a8287;
        transform: rotate(45deg);
        transform-origin: 0% 100%;
        content: '';
      }
    }

    .pill {
      padding: 0 0.6667em;
    }
  }

  &__folder {
    &-name {
      word-break: break-word;
    }
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__uploaded {
    line-height: 1rem;

    &-date {
      line-height: 1.5rem;
    }

    button.table-link.exists {
      padding-right: 0;

      &:hover {
        color: map-get($blue-steps, 500);
      }
    }

    .table-link {
      display: inline;
      font-size: 0.75rem;
      line-height: 1rem;

      &.exists {
        text-decoration: underline;
        cursor: pointer;
      }

      &.removed {
        text-decoration: line-through;
      }
    }

    &-via-tooltip {
      .ry-label {
        margin-bottom: 0;
      }
    }
  }

  &__upload-removed {
    color: #cc2936;
  }

  &__actions {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;

    .tooltip-list-target:hover {
      color: $action-teal;
    }

    &-download,
    &-visibility {
      margin: 0;
      padding: 0.5rem 0.25rem;
      vertical-align: top;
      background: none;
      border: none;
      border-radius: 4px;
      appearance: none;

      .ry-icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      &:hover {
        .ry-icon {
          color: $action-teal;
        }
      }
    }

    &-download {
      cursor: pointer;
    }

    &-more {
      padding: 0.5rem 0.25rem !important;
    }

    &-visibility {
      cursor: help;
    }
  }
}

.file-link-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;

  .pill {
    padding: 0 1em;
  }
}

.archived {
  background: rgba(216, 216, 216, 0.5);
}

.on-behalf-of {
  color: #626374;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
}

.visibility-tooltip {
  &__header {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  &__label {
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}
