@import '../../variables.scss';

@mixin buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;

  .ry-button-group {
    margin-bottom: 0;
  }
}

.milestones {
  position: relative;

  .empty__content .ry-button {
    display: block;
  }

  .ry-toggles {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .ry-card__content {
    // override 1rem
    padding: 1.5rem 0 0 0;

    .empty {
      // add padding back to 3rem 2rem;
      padding: 2.5rem 3rem 4rem 3rem;
    }
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__new-milestone-button {
    position: relative;
    display: block;
    width: 100%;
    padding: 1rem 4rem 1rem 3.5rem;
    color: $action-teal;
    font-weight: 600;
    line-height: inherit;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    appearance: none;

    .ry-icon {
      position: absolute;
      top: 0.75rem;
      left: 0.75rem;
      width: 2rem;
      height: 2rem;
    }

    &:disabled {
      color: #bcc4c4;
      cursor: default;
    }
  }

  .milestones__new-milestone-button,
  .milestone,
  .milestone-form {
    border-top: 1px solid #dce2e6;

    &:nth-child(odd) {
      background-color: #f8f8fa;
    }
  }

  &__active-milestone-timeline {
    padding: 1rem 1.5rem 3rem 2.5rem;
    border-top: 1px solid #dce2e6;

    h3 {
      margin: 0;
      font-weight: 600;
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__empty-milestone-button {
    margin: 1.5rem auto 0;
  }
}

.milestone {
  position: relative;
  // overflow: hidden;

  &::before {
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    left: 1.75rem;
    width: 1px;
    background-color: $ryan-navy;
    content: '';
  }

  &:first-child::before {
    top: 1.5rem;
  }

  &:last-child::before {
    bottom: auto;
    height: 1.5rem;
  }

  &__content {
    padding: 0.5rem 1rem 1.5rem 3.5rem;
  }

  &__buttons {
    @include buttons;

    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 100%;
      margin-top: 0.5rem;

      .ry-button {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }
}

.milestone-header {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: inherit;
  line-height: inherit;

  button {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    min-height: 3.5rem;
    margin: 0;
    padding: 1rem 4rem 1rem 3.5rem;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    appearance: none;

    &:disabled {
      color: inherit;
      cursor: default;

      .milestone-header__caret {
        color: #bcc4c4;
      }
    }
  }

  .status {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &__caret {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .milestone-dates {
    float: right;
    padding-right: 0.5rem;

    @media (max-width: 576px) {
      display: block;
      float: none;
    }
  }
}

.milestone-dates {
  color: #626374;
  font-weight: 600;
  font-size: 0.75rem;
}

.milestone-form {
  &__container {
    padding: 0.5rem 1rem 1.5rem 1rem;
  }

  &__buttons {
    @include buttons;

    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
      align-items: flex-start;

      .ry-button-group {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
}
