.update-savings-bucket-modal {
  .title {
    color: rgb(62, 64, 80);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }

  .call-to-action {
    text-transform: uppercase;
  }

  &__title {
    display: block;
    text-transform: uppercase;
  }
}
