@import '../../../../variables.scss';

.common-column {
  .column-action-button {
    margin: -0.5rem 0;
  }

  .column-title {
    font-weight: 600;
  }

  .comment-button.ry-button.ry-button--icon-only {
    margin: -0.5rem 0;
    padding: 0;
  }

  .past-due {
    color: $error-red;
  }
}
