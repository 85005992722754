@import '../../../variables.scss';

.report-builder-panel {
  position: relative;
  padding: 1rem;
  background-color: white;
  border-top: 1px solid map-get($teal-steps, 400);

  &--open {
    background-color: map-get($gray-steps, 50);
  }

  &--error {
    background-color: change-color($red, $alpha: 0.05);
  }

  &--error &__icon {
    color: $red;
  }

  &--complete &__icon {
    color: $green;
  }

  &__header {
    display: flex;

    :hover {
      cursor: pointer;
    }

    .title {
      flex: 1 1 auto;
      font-weight: 600;
      line-height: 1.5rem;
    }
  }

  &__toggle {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    appearance: none;

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__body {
    margin-top: 1rem;

    .inner-panel {
      margin-bottom: 1rem;
      padding: 1rem;
      font-size: 0.75rem;
      background-color: #fff;
      border: 1px solid map-get($teal-steps, 400);
      border-radius: 4px;
    }

    @media screen and (min-width: 768px) {
      margin-left: 2.5rem;
    }
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    color: map-get($gray-steps, 200);
  }
}
