@import '../../../variables';

.release-notes-modal {
  height: calc(100vh - 1rem);
  padding-top: 0.625rem;
  @media (min-width: 768px) {
    display: table;
    width: 100%;
    height: auto;
    max-height: 80vh;
  }
  &__inner-wrapper {
    max-width: 48.438rem;
    height: calc(100% - 4rem);
    margin: auto;
    @media (min-width: 768px) {
      height: auto;
    }
  }
  .ry-modal__title {
    margin-bottom: 2rem;
    font-weight: 600;
  }
  &__filters {
    margin-bottom: 30px;
    .ry-text-input {
      flex: 1;
    }
    &__top {
      display: flex;
      @media (min-width: 768px) {
        padding: 0 1.75rem;
      }
    }
    &__bottom {
      display: none;
      margin-top: 0.875rem;
      padding: 1.063rem 1.75rem;
      background: $filters-grey;
      &--active {
        display: block;
        @media (min-width: 768px) {
          display: flex;
        }
      }
      &__start-date,
      &__end-date {
        width: 100%;
        @media (min-width: 768px) {
          max-width: 11rem;
        }
      }
      &__start-date {
        margin: 0 1rem 0.938rem 0;
        @media (min-width: 768px) {
          margin: 0 1rem 0 0;
        }
      }
      &__dropdown-wrapper {
        position: relative;
        .dropdown {
          position: relative;
          width: 100%;
          margin: 0 2.25rem 0.938rem 0;
          @media (min-width: 768px) {
            width: 18.813rem;
            margin: 0 2.25rem 0 0;
          }
          &__list {
            position: absolute;
            right: 0;
            left: 0;
          }
        }
        .loading-spin {
          position: absolute;
          top: 1.875rem;
          right: 2.875rem;
          width: 1.875rem;
          height: 1.875rem;
        }
        &--loading {
          .dropdown__selection-label__caret-button {
            visibility: hidden;
          }
        }
      }
    }
  }
}
