.custom-view-well {
  margin-bottom: 1rem;

  .ry-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.25rem;
    vertical-align: middle;
  }

  .ry-label {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }
}
