.edit-user-role-and-permissions-page {
  @media (min-width: 1024px) {
    margin-top: 1rem;
  }

  hr {
    margin: 2rem 0;
  }

  section {
    margin-bottom: 2rem;
  }

  .deactivate-modal {
    &__buttons {
      display: flex;
      margin: 2rem 0 1rem;

      .ry-button-group {
        flex-direction: row;
      }
    }
  }

  .ry-card__content {
    line-height: 2rem;
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  &__edit .ry-button-group {
    justify-content: flex-end;
  }

  &__language {
    .ry-dropdown {
      max-width: 23.75rem;
    }
  }
}
