@import '../../variables';

.manage-new-user-table {
  .ry-table__td {
    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
    }
  }

  .ry-table__tr-group-header {
    padding: 0;
    font-size: 0.75rem;
  }

  .no-wrap {
    white-space: nowrap;
  }

  // Pending pill
  .pill {
    font-weight: 600;
    background: rgba(10, 130, 135, 0.2);
  }

  &__review-link,
  &__edit-link {
    padding: 0;
    color: inherit;
    font-weight: 600;
    text-decoration: none;
    background: none;
    border: 0;
    cursor: pointer;
    appearance: none;

    span {
      vertical-align: middle;
    }
  }

  &__review-link {
    font-size: 0.875rem;
    text-transform: uppercase;

    &:hover {
      color: $action-teal;
    }

    &--disabled {
      color: map-get($gray-steps, 300);
      text-transform: uppercase;
      pointer-events: none;

      &:hover {
        color: map-get($gray-steps, 300);
      }
    }
  }

  &__tooltip {
    color: $action-teal;
    cursor: pointer;

    .ry-icon {
      margin-right: 0.25rem;
      vertical-align: bottom !important;
    }
  }
}
