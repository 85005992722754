.project-snapshot {
  margin-bottom: 0;
  border: none;
  box-shadow: none;

  .ry-card__content {
    padding: 0;
  }

  .row {
    flex-wrap: nowrap;

    @media (max-width: 1180px) {
      flex-direction: column;
    }
  }

  &__highlights {
    padding-left: 0.75rem;

    .col-12 {
      padding-right: 0.25rem;
    }

    .row {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    @media (max-width: 1180px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0;
    }
  }

  &__overview {
    padding: 0;

    @media (max-width: 1180px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 0.5rem;
      padding-left: 0;
    }
  }
}
