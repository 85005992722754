@import '../../variables.scss';

.personal-information-page {
  .level-1 td {
    background-color: white;
  }

  .level-2 td {
    background-color: #f8f8fa;
  }

  .level-3-or-more td {
    background-color: #efeff3;
  }

  .edit-fields-container {
    display: flex;

    .ry-text-input {
      width: 20.4rem;
    }
  }

  .labeled-list li {
    margin-bottom: 0;
  }

  &__acct-tooltip {
    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    .account:nth-child(3) {
      padding-left: 1rem;
    }
  }
}
