@import '../../../variables.scss';

.milestones-timeline {
  &__row {
    position: relative;
    height: 2rem;
    overflow: hidden;
  }

  // pill
  &__milestone {
    position: absolute;
    top: 0.25rem;
    min-width: 1.5rem;
    height: 1.5rem;
    padding: 0 0 0 calc(1.5rem - 2px);
    color: $ryan-navy;
    text-transform: uppercase;
    background: white;
    border-color: $ryan-navy;
    border-radius: 0.75rem;
    cursor: pointer;

    .status {
      position: absolute;
      top: 50%;
      left: -1px;
      display: block;
      transform: translateY(-50%);
    }

    &--active {
      color: white;
      background: $ryan-navy;
    }

    &:hover {
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
    }

    &-title {
      padding-left: 4px;
      overflow: hidden;
      font-weight: 600;
      font-size: 0.75rem;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
    }
  }
}
