@import '../../../variables.scss';

.savings-entry-date-and-currency {
  .info-tooltip {
    margin-right: 1rem;
    margin-left: 0.25rem;
  }

  &__form {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.688rem;

    section {
      display: flex;
      align-items: flex-end;

      button {
        margin-left: 0.75rem;
      }

      .ry-dropdown {
        width: 11.375rem;
        margin-right: 1rem;

        &__label {
          display: flex;
          align-items: center;
          margin-bottom: 0.125rem;

          .info-tooltip > .ry-icon {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }
    }

    .baseline-switch {
      display: flex;
      align-self: center;
      padding-top: 0.875rem;
    }

    .ry-datepicker {
      width: 12rem;
      margin-right: 1rem;
    }

    .well {
      width: 13.5rem;
      margin-left: auto;

      li {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: initial;

        label {
          flex-basis: 100%;
        }

        button.ry-button {
          margin-left: auto;
          padding: 0;

          .ry-icon {
            color: #3e4050;

            &:hover {
              color: $action-teal;
            }
          }
        }
      }
    }

    &--short {
      align-items: initial;
      margin-bottom: 1rem;
    }
  }
}
