@import '../../variables.scss';

.manage-attachments {
  &__inner {
    margin-bottom: 2rem;

    &--error {
      margin-bottom: 0;
      padding: 1rem 1rem 0 1rem;
      border: 1px solid #cc2936;
      border-radius: 4px;
    }

    .file-attach {
      margin: 0 0 2rem 0;
    }

    &__existing {
      margin: 0 0 2rem 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 0;
        padding: 0;
        border-color: #aab9bf;
        border-style: solid;
        border-width: 1px 1px 0 1px;

        &:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        &:last-child {
          border-bottom-width: 1px;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      .ry-icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      &-name {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem 1rem 1rem 3rem;

        .ry-icon {
          position: absolute;
          top: 1rem;
          left: 1rem;
        }
      }

      &-delete {
        padding: 1rem;
        color: inherit;
        line-height: inherit;
        background: none;
        border: none;
        cursor: pointer;
        appearance: none;

        &:hover {
          color: $action-teal;
        }
      }
    }
    &-actions {
      display: flex;
      flex-direction: row;
    }
  }

  &__error-message {
    color: #cc2936;
  }
}
