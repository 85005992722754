@import '../../variables';

.breadcrumb-link-wrapper {
  display: inline;

  .ry-breadcrumb {
    cursor: pointer;
  }

  button.ry-breadcrumb {
    color: $body__color;

    &:hover {
      color: $action-teal;
      text-decoration: none;
    }
  }
}
