.now-or-later {
  &__datepicker {
    max-width: 300px;
    margin-left: 3rem;
  }

  .ry-radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
