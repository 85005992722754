@mixin image-width($width) {
  .public-layout__picture {
    width: $width;
  }

  .public-layout__content,
  .public-layout__footer {
    margin-left: $width;
  }
}

.public-layout {
  h1 {
    margin-bottom: 0.5rem;
  }

  &__picture {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    background-image: url('../../images/public-background.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    box-shadow: inset 0 0 9px rgba(1, 14, 70, 0.34);
  }

  // styled for x-large breakpoint
  &__content {
    position: static;
    margin-left: 50%;
    padding: 6rem 6.5rem;
  }
  &__logo {
    position: relative;
    right: 1rem; // offset the left padding in 'content'
    display: inline-block;

    img {
      display: block;
      height: 5.625rem;
    }
  }

  &__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    margin-left: 50%;
    padding: 1.5rem;
    color: #0e247e;
    font-size: 14px;
    text-align: right;
    background-color: white;

    @media (max-width: 1024px) {
      display: block;
      text-align: left;
    }

    a {
      color: #0e247e;
      text-decoration: none;
    }

    &-copyright {
      flex: 1 1 auto;

      &:first-child {
        @media (max-width: 1024px) {
          text-align: right;
        }
      }
    }

    &-nav {
      flex: 1 1 auto;
      text-align: left;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 1rem;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
      transform: translateY(-100%);
      content: '';
    }
  }

  // large breakpoint
  @media (max-width: 1024px) {
    @include image-width(40%);

    &__content {
      padding: 4.5rem;
    }
  }

  // medium breakpoint
  @media (max-width: 768px) {
    @include image-width(3rem);

    &__content {
      padding: 3rem;
    }
  }

  // small breakpoint
  @media (max-width: 375px) {
    @include image-width(0);

    &__picture {
      display: none;
    }

    &__content {
      margin: 0;
      margin-left: 0;
      padding: 1.5rem 1rem;
    }
  }
}

body.with-public-layout {
  background-color: white;

  @media (min-width: 321px) {
    // Reposition the Toast component when PublicLayout is active.
    .ry-toast-container {
      left: 75%;
    }
  }
}
