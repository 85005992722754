@import '../../../variables.scss';

.file-autocomplete {
  .bs b,
  b {
    display: inline;
  }

  .ry-autocomplete__option--highlighted:not(:hover) {
    background-color: rgba(map-get($teal-steps, 600), 0.08);

    &,
    & b {
      color: map-get($teal-steps, 600);
    }
  }
}
