.milestone-tasks {
  h4 {
    margin: 0 0 0.5rem;
    font-weight: inherit;
    font-weight: 600;
    line-height: inherit;
  }

  .ry-skeleton {
    margin: 1rem 0;
  }

  &__tasks {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;

    p {
      margin: 1rem 0;
    }
  }

  &__task {
    position: relative;
    display: block;
    padding: 0.75rem 0 0.75rem 1.75rem;
    color: inherit;
    font-weight: 600;
    font-size: 0.875rem;
    text-decoration: none;

    & + & {
      border-top: 1px solid #d8d8d8;
    }

    &:hover {
      text-decoration: underline;
    }

    .status {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      // width: 1.25rem;
      // height: 1.25rem;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
}
