.user-details {
  line-height: 2rem;

  ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .ry-icon {
    position: relative;
    top: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    vertical-align: top;
  }

  .ry-skeleton {
    max-width: 50%;
    margin-top: 1rem;
  }
}
