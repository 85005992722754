.notification-preference-card {
  &__ry-skeleton {
    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 1.5rem;

      td {
        padding: 0.5rem;
        border-bottom: 1px solid #d8d8d8;
      }

      // expand column
      td:nth-child(1) {
        width: 1rem;
      }

      // expand column
      td:nth-child(2) {
        width: 80%;
      }

      // checkbox columns
      td:nth-child(3),
      td:nth-child(4) {
        width: 2rem;
      }
    }
  }
}
