.files-tab {
  .file-directory__header {
    padding-top: 0;
    padding-bottom: 1rem;
  }

  .file-directory {
    margin-bottom: 0;
  }

  &__direct-link {
    display: flex;
    align-items: flex-start;
    font-size: 0.875rem;
    line-height: 1rem;
    background-color: #fefbf6;
    border-bottom: 1px solid #d2d3dc;

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin: 1rem 0.75rem;
      color: #edae49;
    }

    &-text {
      flex: 1 1 auto;
      padding: 1.25rem 0.5rem;
    }

    .ry-button {
      margin: 0.5rem 0.75rem;

      &:focus {
        position: relative;
      }
    }
  }

  &__upload-files {
    margin-bottom: 1rem;
  }
}
