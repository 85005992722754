@import '../../../variables';

.contracts-page {
  &__header {
    margin: 1rem 0;

    h2 {
      margin-bottom: 0.5rem;
    }

    .ry-text-input {
      width: 100%;
    }

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-view-toggle {
        flex: 1 1 auto;
      }

      h2 {
        margin-bottom: 0;
      }

      .ry-text-input {
        width: 18rem;
      }
    }
  }

  &__explorer {
    padding: 0.75rem 0.5rem;
    line-height: 2rem;
    border-color: #d8d8d8;
    border-style: solid;
    border-width: 1px 0;

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.25rem 0.5rem 0.25rem 0;
      color: inherit;
    }
  }
}
