@import '../../../mixins.scss';

.contact-information {
  h3 {
    margin: 0 0 1rem 0;
    font-weight: 600;
    font-size: inherit;
    line-height: inherit;
  }

  &__avatar-edit {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .ry-button {
      font-size: 0.875rem;
    }

    @include ry-avatar-scaled(2.5);
  }

  &__email-field {
    margin-bottom: 2rem;

    .ry-text-input {
      margin-bottom: 0;
    }

    .ry-button {
      padding-left: 0;
    }
  }

  &__address-info {
    @media (min-width: 1024px) {
      height: 100%;
      margin-left: 1rem;
      padding-left: 2rem;
      border-left: 1px solid #d2d3dc;
    }
  }

  &__switch-button {
    display: flex;
    margin: 1.5rem 1rem;
    color: #626374;
    font-size: 0.75rem;
    text-transform: uppercase;

    .ry-switch {
      margin-right: 0.5rem;
    }

    .ry-label {
      margin: auto 0;
    }
  }

  &__update-button {
    display: flex;
    justify-content: flex-end;
  }
}
