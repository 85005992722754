.maintenance {
  .public-layout__picture {
    display: none;
  }

  .public-layout__content,
  .public-layout__footer {
    margin-left: inherit;
  }

  .maintenance-page {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -12rem;
    padding: 6rem;
    inset: 0;

    &__main {
      margin-top: 3rem;
      margin-bottom: 3rem;
      font-size: 40px;
    }

    &__details {
      max-width: none;
      font-weight: 600;
      font-size: 24px;
    }
  }
}
