@import '../../../variables';

.security-card {
  &__reset-password {
    padding-bottom: 0.5rem;
  }

  .ry-text-input__icon-button {
    .ry-icon {
      color: $ryan-gray;
    }
  }

  &__reset-password-button {
    .ry-button {
      display: inline;
      box-sizing: inherit;
      padding: 0 0.25rem;
      font-weight: 600;
      font-size: inherit;
      font-family: inherit;
      letter-spacing: inherit;
      text-align: inherit;
      text-transform: capitalize;
      background: none;
      border: none;
      border-radius: inherit;
      appearance: none;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        width: 100%;
      }
    }
  }

  &__expiration-days {
    margin-top: -0.75rem;
    padding: 0.375rem 2.75rem 0.375rem 0;
    font-size: 1rem;

    &--expiring {
      b {
        color: $red;
      }
    }
  }
}
