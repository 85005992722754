.data-request-page {
  .ry-card__content {
    padding: 0;
  }

  &__card-content {
    padding: 1.5rem;
  }

  &__card-actions {
    display: flex;
    justify-content: right;
    padding: 0.25rem;
    border-top: 1px solid #d2d3dc;
  }

  &__aside {
    .well:not(:first-child),
    .well + .ry-button {
      margin-top: 1rem;
    }
  }

  .well {
    &__status {
      display: flex;

      &__dash {
        margin: 0 0.4rem;
      }
    }

    hr {
      margin: 1rem 0;
    }
  }

  // Skeletons

  .sk-status {
    max-width: 5rem;
  }

  // Deleted

  &__deleted {
    padding: 4rem 0;
    text-align: center;

    img {
      margin-bottom: 1rem;
    }

    p {
      margin: 0 auto;
    }
  }

  &__follow-action-container {
    &__separator {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  &__code-note__button {
    position: absolute;
    left: 0;
  }
}
