@import '../../variables.scss';

.multiselect-daterange {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &__add-button {
    .ry-button.ry-button--icon-before {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      background-color: transparent;
      border: 0;
      cursor: pointer;
    }
  }
}
