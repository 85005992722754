#debug-banner {
  .ry-banner__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
  }

  .ry-h4 {
    margin-right: 10px;
  }

  .ry-link {
    margin-left: 10px;
  }
}
