.manage-team-search {
  &__header {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;

    .ry-text-input {
      flex: 0 0 18rem;
    }
  }

  &__name-email {
    margin: -0.75rem 0;
  }

  &__edit-user {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: #0a8287;
    }

    .ry-icon {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
    }
  }
}
