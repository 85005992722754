.data-request-form {
  & > .dropdown {
    position: relative;
    margin-bottom: 32px;
    .dropdown__list {
      position: absolute;
      right: 0;
      left: 0;
      z-index: 1;
    }
  }
}
