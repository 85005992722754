@import '../../variables.scss';

.project {
  h1 {
    margin: 0;

    .account-hierarchy-tooltip-target {
      position: relative;
      top: -0.35rem;

      .ry-icon {
        width: 2.5rem;
        height: 2.5rem;
        color: $action-teal;
      }
    }
  }

  .ry-tabs {
    margin: 2rem 0 0 0;
  }

  &__well {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    margin: 1rem 0 0 0;
    padding: 0;
    padding-top: 0.25rem;
    list-style: none;
  }

  &__well-item {
    display: inline-block;
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1rem;
    white-space: nowrap;
    vertical-align: middle;

    label {
      display: block;
      margin: 0;
      line-height: inherit;
    }

    .pill {
      position: relative;
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }

      @media (min-width: 601px) {
        height: 2rem;
        font-size: 1rem;
        line-height: 2rem;
      }

      @media (max-width: 600px) {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.ghosted {
        padding-left: 2.25rem;
        line-height: calc(1.5rem - 4px);
        background-color: white;
        border: 2px solid #edae49;
        cursor: help;
        appearance: none;

        .ry-icon {
          position: absolute;
          top: 50%;
          left: 0.25rem;
          width: 2rem;
          height: 2rem;
          transform: translateY(-50%);
        }

        @media (min-width: 601px) {
          padding-left: 2.75rem;

          .ry-icon {
            left: 0.5em;
          }
        }

        @media (max-width: 600px) {
          font-size: 0.6rem;
        }
      }
    }

    .ry-split-button .ry-dropdown-menu {
      font-size: initial;
      line-height: initial;
    }

    &--wrap {
      white-space: initial;
    }

    &__restrictions {
      max-width: 7.813rem;
    }

    &__button {
      padding-top: 0.75rem;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 767px) {
    &__well.well {
      padding: 0.5rem;
    }

    &__well-item {
      width: 50%;
      padding: 0.5rem;
      white-space: initial;
      vertical-align: top;
    }
  }
}
