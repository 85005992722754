$bucketcolors: (
  'potential': #a6caff,
  'submitted': #00beed,
  'approved': #0e247e,
  'received': #edae49
);

.savings-summary-data-viz {
  font-size: 1.5rem;

  .ry-number-1 {
    margin-bottom: 0;
  }

  .ry-number-2 {
    margin-bottom: 0;
  }

  &__text {
    align-self: flex-end;
    font-size: 0.75rem;
  }
  &__label {
    margin-bottom: 0;
    color: #626374;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.85rem;
    text-transform: uppercase;
  }

  .arrow {
    width: 2rem;
    height: 2rem;
    margin-top: 0.25rem;
    color: #d8d8d7;

    &-up {
      color: #217e38;
    }
    &-down {
      color: #cc2936;
    }
  }

  //This is the grey bar 'behind' all of the sections
  .bar-graph {
    position: relative;
    height: 0.25rem;
    overflow: hidden;
    background-color: #d8d8d7;
    border-radius: 0.5rem;

    .bar-chart {
      margin: 0;
    }

    &--increase {
      background-color: #217e38;
    }

    &--decrease {
      background-color: #cc2936;
    }

    &--changes {
      &-increase {
        background-color: transparent;
        border: none;
        border-top: 0.25rem dashed #217e38;
      }
      &-decrease {
        background-color: transparent;
        border: none;
        border-top: 0.25rem dashed #cc2936;
      }
      &-neutral {
        background-color: transparent;
        border: none;
        border-top: 0.25rem dashed #d8d8d7;
      }
    }

    &__section {
      position: absolute;
      top: 0;
      bottom: 0;

      @each $bucket, $color in $bucketcolors {
        &[data-bucket='#{$bucket}'] {
          background-color: $color;
        }
      }
    }
  }

  &__footnote {
    display: flex;

    .info-tooltip {
      width: 1.4375rem;
      height: 1.4375rem;
      padding-top: 1px;
    }
  }

  &__total {
    &-row {
      display: flex;
      justify-content: space-between;

      .savings-summary-data-viz__text {
        right: 0;
        margin-bottom: 0;
      }
    }

    .arrow {
      width: 3rem;
      height: 3rem;
    }
  }

  &__breakdown {
    position: relative;
    padding-bottom: 1.3rem;

    &-header {
      display: flex;
      align-items: center;
    }

    &-marker {
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 0.25rem;
      border-radius: 0.125rem;

      @each $bucket, $color in $bucketcolors {
        &[data-bucket='#{$bucket}'] {
          background-color: $color;
        }
      }
    }

    &-amount-percent {
      display: flex;

      .savings-summary-data-viz__text {
        align-self: flex-end;
        margin-bottom: 3px;
        padding-left: 0.25rem;
      }
    }

    .info-tooltip {
      position: absolute;
      right: 0.25rem;
      bottom: 2.25rem;
    }
  }
}
