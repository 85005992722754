@import '../../variables';

.followers-modal {
  max-width: 530px;

  .instructions {
    text-align: center;
  }

  .ry-autocomplete {
    margin-top: 2em;
    margin-bottom: 1.5em;
  }

  .user-list > div:first-of-type {
    border-top: solid 1px #d2d3dc;
  }

  .user-list-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 57px;
    line-height: 52px;
    border-bottom: solid 1px #d2d3dc;

    &__avatar {
      margin-top: 12px;
      margin-right: 8px;
      margin-bottom: 13px;
      margin-left: 8px;
    }

    &__action {
      width: 50px;

      button {
        color: $red;
        vertical-align: middle;
      }
    }

    &__name {
      flex: 1;
      font-weight: 600;
    }

    &__user-name {
      vertical-align: middle;
    }
  }
}
