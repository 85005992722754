@import '../../variables.scss';

$timelines__gutter-width: 2rem;

.timelines-calendar {
  &__mobile-engagements {
    padding: 0 1rem;
  }

  &__mobile-engagement {
    position: relative;
    display: block;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    overflow: hidden;
    color: inherit;
    text-decoration: none;
    background-color: map-get($gray-steps, 50);
    border: 1px solid $ryan-navy;
    border-top-width: 0.5rem;
    border-radius: 4px;

    &--inactive {
      color: #626374;
      background-color: #efeff3;
      border-color: #626374 !important;
    }

    .ry-icon {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      z-index: 1;
      width: 2rem;
      height: 2rem;
      transform: translateY(-50%);
    }

    &:hover .ry-icon {
      color: $action-teal;
    }
  }

  &__row {
    position: relative;
    height: 4.5rem;
    overflow: hidden;
  }

  &__toolTip {
    width: 30rem;
  }

  &__engagement {
    position: absolute;
    top: 50%;
    padding: 0.5rem 3.5rem 0.5rem 0.75rem;
    overflow: hidden;
    color: inherit;
    text-decoration: none;
    background-color: white;
    border: 1px solid $ryan-navy;
    border-left-width: 0.5rem;
    border-radius: 4px;
    transform: translateY(-50%);
    cursor: pointer;
    transition: box-shadow 150ms ease-out;

    &--ol {
      border-left-color: transparent !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--or {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--inactive {
      color: #626374;
      background-color: #efeff3;
      border-color: #626374 !important;
    }

    .ry-icon {
      position: absolute;
      top: 50%;
      right: 0.75rem;
      z-index: 1;
      width: 2rem;
      height: 2rem;
      transform: translateY(-50%);
    }

    &:not(&--inactive):hover {
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);

      .ry-icon {
        color: $action-teal;
      }
    }
    // Text treatments, reused in Tooltip
    &-dates {
      overflow: hidden;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1rem;
      white-space: nowrap;
      text-transform: uppercase;
      text-overflow: ellipsis;
    }

    &-client-name {
      overflow: hidden;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1rem;
      white-space: nowrap;
      text-transform: uppercase;
      text-overflow: ellipsis;
    }

    &-client-longName {
      margin-top: 0.25rem;
      margin-bottom: 0;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &-accountName {
      margin-bottom: 0.75rem;
      font-size: 0.75rem;
      line-height: 1rem;
    }

    &-details {
      display: -webkit-box;
      max-width: 100%;
      height: auto;
      margin-bottom: 0.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0;
      overflow: hidden;
      font-size: 14px;
      line-height: 1.5rem;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    &-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__well {
      margin-bottom: 0.5rem;
      padding: 1rem;
      padding-bottom: 0.4rem;
      font-size: 0.875rem;
      line-height: 1rem;
      vertical-align: middle;

      label {
        display: block;
        margin: 0;
        line-height: inherit;
      }

      .labeled-list.row li {
        margin-bottom: 0;
      }
    }
  }

  &__cta {
    margin-top: 3rem;
    text-align: center;
  }

  &__end {
    display: inline-block;
    padding: 0 0.5rem;
    color: #a3a4b2;
    font-size: 0.75rem;
    text-transform: uppercase;
    background-color: map-get($gray-steps, 50);
  }

  &__empty {
    padding: 4rem 0;
    text-align: center;
    filter: drop-shadow(0 0 0.5rem map-get($gray-steps, 50));

    .ry-icon {
      color: #a3a4b2;
    }

    &-text {
      display: inline-block;
      padding: 0.5rem 0.5rem 0.75rem 0.5rem;
    }

    .scrolling-calendar__body & {
      .ry-icon {
        background-color: map-get($gray-steps, 50);
      }

      &-text {
        background-color: map-get($gray-steps, 50);
      }
    }
  }
}

.sk-mobile-engagement {
  height: 5rem;
  margin-bottom: 0.5rem;
}
