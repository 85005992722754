@import '../../../variables';

.contract-files-page {
  &__actions {
    display: flex;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    white-space: nowrap;

    .ry-modal {
      white-space: initial;
    }
  }

  &__button {
    padding: 0 0 0 0.5rem;
  }
}
