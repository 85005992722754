// small breakpoint
.page-help-form {
  &__assistance {
    margin: 2.5rem 0 4rem 0;

    &-icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    a {
      display: inline-block;
      color: #0a8287;
      text-decoration: none;
    }
  }
}
