.files-upload-modal {
  width: 30%;
  min-width: 500px;
}

.collapsible-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dae0f1;

  #success {
    width: 45px;
    padding-left: 6px;
  }

  #chevron {
    background: none;
    border: none;

    svg {
      width: 25px;
      height: '100%';
    }
  }
}

.gray {
  background-color: #f8f8fa;
}

.children {
  padding-right: 10px;
  padding-left: 10px;
}

.expanded {
  max-height: 2000px;
}

.collapsed {
  max-height: 0;
  overflow: hidden;
}

.next-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 33px;
}

.dss-file-upload-container {
  padding-bottom: 33px;
}

.link-filter-button {
  display: inline-block;
  height: 32px;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-weight: 600;
  font-size: 1rem;
  font-family: 'myriad-pro', sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.125rem;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid rgb(210, 211, 220);
  border-radius: 4px;
}

.selected {
  color: white;
  background: rgb(14, 36, 126);
  border: none;
}

// Select Stuff

.file-upload-select-dropdown {
  .ry-text-input {
    margin: 0 !important;
  }

  margin-bottom: 1rem !important;

  .show {
    background-color: #fff;

    button {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border: none;

      .select-option {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem;

        #option-createdBy {
          font-size: 12px;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .hide {
    display: none;
  }

  ul {
    position: relative;
    display: block;
    max-height: 400px;
    margin: 0;
    margin-bottom: 0.5rem;
    padding: 0;
    overflow-y: scroll;
    list-style-type: none;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(233, 233, 233);
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    li {
      width: 100%;
      height: 56px;
      color: rgb(43, 44, 54);
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0;

      button:hover {
        background-color: #ebf5f5;
      }
    }
  }
}
