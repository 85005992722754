.files-tab {
  .file-directory__header {
    padding-top: 0;
    padding-bottom: 1rem;
  }

  .file-filter-dropdown {
    width: 150px;
    padding-left: 1rem;
  }
}
