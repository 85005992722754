@use 'sass:math';

/* stylelint-disable no-invalid-position-at-import-rule */
@import '../../../variables.scss';

.account-switcher-options {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  list-style: none;

  &__account {
    background-color: map-get($gray-steps, 100);

    &[data-level-color='1'] {
      background-color: white;
    }

    &[data-level-color='2'] {
      background-color: map-get($gray-steps, 50);
    }

    // indentation
    @for $i from 2 through 9 {
      &[data-level-indent='#{$i}'] {
        .account-switcher-options__account-target {
          padding-left: ($i - 1) * 2.75rem;

          // reduce indentation on mobile when checkboxes are not enabled
          :not(.account-switcher-options--with-checkboxes) > & {
            @media (max-width: 767px) {
              padding-left: ($i - 1) * 2rem;
            }
          }
        }
      }
    }

    // target holds (1) button/bullet (2) account link
    &-target {
      position: relative;
      display: flex;
      border-bottom: 1px solid map-get($gray-steps, 200);
    }

    // (2) link
    &-link {
      position: relative;
      display: block;
      width: 100%;
      padding: 0.5rem 1rem 0.5rem 0;
      color: inherit;
      line-height: inherit;
      text-decoration: none;
      background: none;
      border: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      // text line, left and right
      &-line {
        position: absolute;
        top: 50%;
        right: 1rem;
        left: 0;
        padding-right: 3.5rem; // buffer for project count (supports 6 figures)
        text-align: left;
        transform: translateY(-50%);
      }
    }

    // (including subsidiaries)
    &-name {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      small {
        font-style: italic;
      }
    }

    // teal for active accounts or search match
    &--active > &-target &-name,
    b {
      color: $action-teal;
    }
  }

  // (1) bullet
  &__bullet {
    position: relative;
    flex: 0 0 auto;
    width: 3.5rem;
    height: 3.5rem;

    &::before {
      position: absolute;
      top: math.div(3.25rem, 2);
      left: math.div(3.25rem, 2);
      display: block;
      width: 0.25rem;
      height: 0.25rem;
      background-color: $ryan-gray;
      border-radius: 50%;
      content: '';
    }

    @media (max-width: 767px) {
      width: 2.5rem;
      height: 3rem;

      &::before {
        top: math.div(2.75rem, 2);
        left: math.div(2.25rem, 2);
      }
    }
  }

  // (1) toggle button (expands and collapses child accounts)
  &__toggle {
    display: inline-block;
    flex: 0 0 auto;
    padding: 1rem;
    vertical-align: top;
    background: none;
    border: none;
    cursor: pointer;
    appearance: none;

    @media (max-width: 767px) {
      padding: 0.75rem 0.5rem;
    }

    &:focus {
      // @todo remove once component library supports this
      outline: 0;
      box-shadow: 0 0 0 1px #fff, 0 0 0 4px #b2d6e8;
    }

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__engagement-count {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 1rem;
  }

  &--with-checkboxes {
    .ry-checkbox {
      margin: 0.25rem 0 0.25rem 0.25rem;

      @media (max-width: 767px) {
        margin: 0;
      }

      &__label {
        padding-right: 0;
      }
    }

    // (1) toggle button (expands and collapses child accounts)
    button {
      padding: 1rem 1rem 1rem 0.5rem;

      @media (max-width: 767px) {
        padding: 0 1rem 0 0.5rem;
      }
    }

    // (1) bullet
    .account-switcher-options__bullet {
      width: 3rem;

      &::before {
        left: 1.125rem;
      }
    }
  }
}
