@import '../../../variables.scss';

.projects-tasks-page {
  &__info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 6rem;
    margin-bottom: 0;
    padding: 1.25rem 1.5rem;
    background: #f8f8fa;

    p {
      margin-bottom: 0;
      padding-right: 0.25rem;
      color: $ryan-gray;
    }

    @media (max-width: 767px) {
      display: block;

      p {
        margin-bottom: 1rem;
        padding-right: 0;
      }
    }
  }

  // for the task table
  // @todo prop on component
  tr.pastdue td {
    background-color: #fcf4f5;
    .status,
    .status__icon,
    .duedate {
      color: $red;
    }
  }
}
