@import '../../variables.scss';

.file-attach-button {
  padding: 0.75rem 0.25rem;
  color: $action-teal;
  font-weight: 600;
  font-size: inherit;
  line-height: 2rem;
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  appearance: none;

  .file-attachments + & {
    margin-top: 0.5rem;
  }

  .ry-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.25rem 0.25rem 0.25rem 0;
    vertical-align: top;
  }

  &:hover {
    color: #004247;
  }

  &:active {
    color: #002125;
  }
}
