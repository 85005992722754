@import '../../../variables';

.contract-folders-page {
  &--is-ryan {
    .ry-table__th:nth-child(2) {
      button {
        padding-left: 1.2rem;
      }
    }
  }

  .ry-button {
    padding: 0;
    color: inherit;

    &.ry-link {
      text-align: left;
    }
  }

  .ry-table__td__engagement-id {
    display: inline-block;
    width: 6.25rem;
    text-align: right;
  }
}
