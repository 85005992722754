.screen {
  &__screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2999;
    text-align: center;
    background-color: white;
    opacity: 1;
    transition: opacity 0.25s ease-out;

    &::before {
      position: absolute;
      top: 1rem;
      right: 1rem;
      bottom: 1rem;
      left: 1rem;
      content: '';
    }

    &-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    // .ry-icon {
    //   width: 2rem;
    //   height: 2rem;
    // }
  }

  &--revealing &__screen {
    opacity: 0;
  }

  &--revealed &__screen {
    display: none;
  }
}
