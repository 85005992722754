@import '~@ryan/components/src/styles/variables';

.data-request-overview {
  flex: 1 0 auto;
  max-width: 50%;

  > h3,
  > h4 {
    margin: 0;
    font-weight: 600;
  }

  > h2,
  > h4 {
    text-transform: uppercase;
  }

  > h2 {
    margin: 0 0 0.25rem;
    color: map-get($blue-steps, 700);
    font-weight: 700;
    font-size: 0.875rem;
    letter-spacing: 0.125rem;
  }

  > h3 {
    margin-bottom: 0.25rem;
    font-size: 1.125rem;
  }

  > h4,
  .attachments > .ry-label {
    color: #626374;
    font-size: 0.75rem;
  }

  .attachments {
    padding-top: 8px;
  }

  &__actions {
    display: flex;
    margin-top: 2.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid map-get($gray-steps, 200);

    button:first-child {
      margin-right: 1rem;
      margin-left: auto;
    }
  }

  &__type {
    .data-request-overview & {
      margin-bottom: 1rem;
      font-size: 14px;
    }
  }
}
