.file-link {
  word-break: break-word;
}

a,
button.ry-link {
  &.bs--disabled {
    cursor: default;

    &:hover b {
      color: inherit;
      text-decoration: none;
    }
  }
}
