.code-notes-drawer {
  &__tooltip {
    display: inline-block;
    line-height: inherit;
    cursor: pointer;

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__subheader {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;

    &:last-of-type {
      margin-bottom: 12px;
    }
  }
}
