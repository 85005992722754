@import '../../../../variables';

.release-note {
  height: calc(100% - 4.875rem);
  overflow-x: hidden;
  overflow-y: auto;
  @media (min-width: 768px) {
    height: calc(60vh - 40px);
    padding: 0 1.75rem;
  }
  .loading-spin {
    position: absolute;
    bottom: 2.5rem;
    left: calc(50% - 1.5rem);
    &--top {
      top: 13.75rem;
      bottom: auto;
    }
  }
  &__no-result {
    margin-top: 1rem;
    color: $result-gray;
    font-size: 1rem;
    text-align: center;
  }
  &__empty {
    overflow: visible;
  }
  &--opened {
    height: calc(100% - 20.75rem);
    @media (min-width: 768px) {
      height: calc(60vh - 7.5rem);
    }
  }
}
