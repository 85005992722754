@import '../../variables.scss';

$scrolling-calendar__gutter-width: 2rem;
$scrolling-calendar__background-color: #f8f8fa;

.scrolling-calendar {
  transition: opacity 150ms ease-out;

  &--loading {
    opacity: 0.5;
  }

  &__header {
    display: flex;
    align-items: center;
    height: 3.5rem;
    color: white;
    background-color: $ryan-gray;

    button {
      display: block;
      width: $scrolling-calendar__gutter-width;
      height: 3.5rem;
      padding: 0;
      color: inherit;
      background: none;
      border: none;
      cursor: pointer;

      .ry-icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &-months {
      display: flex;
      flex: 1 1 auto;
    }

    &-month {
      width: 8.33333333333%;
      height: 2rem;
      padding: 0 0.25rem;
      overflow: hidden;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 2rem;
      white-space: nowrap;
      text-align: center;
      text-transform: uppercase;
      text-overflow: ellipsis;

      & + & {
        border-left: 1px solid white;
      }
    }
  }

  // contains __content and __background
  &__body {
    position: relative;
    min-height: 5rem;
    margin: 0;
    padding: 0;
    background-color: $scrolling-calendar__background-color;

    // left and right fades
    &::before,
    &::after {
      position: absolute;
      top: 1.5rem;
      bottom: 0;
      z-index: 10;
      width: 1.5rem;
      content: '';
      pointer-events: none;
    }

    &::before {
      left: 0;
      background-image: linear-gradient(
        90deg,
        $scrolling-calendar__background-color 30%,
        rgba(255, 255, 255, 0)
      );
    }

    &::after {
      right: 0;
      background-image: linear-gradient(
        270deg,
        $scrolling-calendar__background-color 30%,
        rgba(255, 255, 255, 0)
      );
    }

    .scrolling-calendar--navigable & {
      padding: 0 $scrolling-calendar__gutter-width;

      &::before,
      &::after {
        width: (2rem + $scrolling-calendar__gutter-width);
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;

    // must be at least 7.5 for the bottom fade to not reach the header
    min-height: 7.5rem;
    padding: 1.5rem 0 2rem 0;
  }

  // contains __stripe(s) and __now-line
  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    // bottom fade
    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 6rem;
      background-image: linear-gradient(
        0deg,
        $scrolling-calendar__background-color 50%,
        rgba(255, 255, 255, 0)
      );
      content: '';
    }

    .scrolling-calendar--navigable & {
      right: $scrolling-calendar__gutter-width;
      left: $scrolling-calendar__gutter-width;
    }
  }

  &__stripe {
    position: absolute;
    top: 1.5rem;
    bottom: 0;
    width: 1px;
    background-color: #c2c2c7;
  }

  &__now-line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: $ryan-navy;
    background-image: repeating-linear-gradient(
      180deg,
      $ryan-navy,
      $ryan-navy 0.25rem,
      $scrolling-calendar__background-color 0.25rem,
      $scrolling-calendar__background-color 0.5rem
    );

    &-label {
      position: absolute;
      top: 0.25rem;
      left: 50%;
      z-index: 1;
      color: $ryan-navy;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1rem;
      white-space: nowrap;
      background-color: #f8f8fa;
      transform: translateX(-50%);
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 0.5rem;
      height: 0.5rem;
      background-color: #f8f8fa;
      transform: translate(-50%, -50%) rotate(45deg);
      content: '';
    }
  }
}
