@import '../../../variables';

.projects-overview {
  &--is-ryan {
    .ry-table__th:nth-child(4) {
      button {
        padding-left: 1.2rem;
      }
    }
  }

  .ry-table__td__engagement-id {
    display: inline-block;
    width: 6.25rem;
    text-align: right;
  }

  .ry-table {
    margin-bottom: 2rem;

    &__expanded {
      padding-left: 1.5rem;
    }
  }

  &__pills {
    white-space: nowrap;
    vertical-align: top;
  }
  .filter {
    .filter-action-container {
      display: flex;
      flex-direction: row;

      .ry-dropdown {
        width: 8rem;
        margin-right: 1rem;
      }
    }
  }
  .pill {
    vertical-align: top;

    &.active {
      background: rgba(10, 130, 135, 0.25);
    }

    &.inactive {
      background: rgba(74, 74, 74, 0.25);
    }
    &.ghosted {
      margin-left: 0.25rem;
      padding: 0;
      line-height: calc(2rem - 4px);
      background-color: white;
      border: 1px solid #edae49;
      cursor: help;
      appearance: none;

      .ry-icon {
        position: relative;
        top: calc(-0.25rem - 1px);
        width: 2rem;
        height: 2rem;
        margin-bottom: 0;
      }
    }
  }

  .level-1 td {
    background-color: white;
  }

  .level-2 td {
    background-color: #f8f8fa;
  }

  .level-3-or-more td {
    background-color: #efeff3;
  }
}
