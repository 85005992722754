.role-permissions {
  th {
    background-color: #3e4050;
  }
}

.rp-table {
  width: 100%;
  border-collapse: collapse;

  th {
    padding: 1rem;
    color: white;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: left;
    text-transform: uppercase;
  }

  td {
    padding: 0.75rem;
    vertical-align: top;
    border-bottom: 1px solid #d2d3dc;

    &.rp-table__permission-name {
      padding-left: 3.25rem;
    }
  }

  tr:nth-child(odd) td {
    background-color: #f8f8fa;
  }

  &__group-header {
    td {
      padding: 0;
      background-color: #efeff3 !important;
      border-bottom: 1px solid #a3a3b2;
    }

    button {
      display: block;
      width: 100%;
      padding: 0.75rem;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1.5rem;
      text-align: left;
      text-transform: uppercase;
      vertical-align: top;
      background: none;
      border: none;
      cursor: pointer;
      appearance: none;

      &:focus {
        position: relative;
        z-index: 1;
      }

      .ry-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }
    }
  }

  &__checkboxes {
    white-space: nowrap;
  }

  .ry-checkbox,
  .checklocks {
    display: inline-block;
    margin: 0;
    vertical-align: top;
  }

  .ry-checkbox {
    display: inline-block;

    &__label {
      padding-right: 0;
    }
  }

  .checklocks {
    display: inline-block;
  }

  th:nth-child(1) {
    width: 80%;
  }

  td:nth-child(1) .ry-skeleton {
    display: inline-block;
    min-width: 10rem;

    &:not(:first-child) {
      min-width: 80%;
    }
  }

  td:nth-child(2) .ry-skeleton {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.75rem;
    vertical-align: top;
  }

  p {
    margin: 0;
  }
}
