.last-updated-card {
  .ry-card__content {
    padding: 1rem 0 0 0;
  }

  &__link {
    display: block;
    padding: 1rem;
    color: inherit;
    text-decoration: none;
    border-top: 1px solid #d2d3dc;

    &:hover {
      .ry-icon {
        color: #00508a;
      }

      .last-updated-card__link__content--display-name {
        color: #00508a;
        text-decoration: underline;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;

      span {
        display: block;
      }

      .ry-icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      &--account-name {
        font-size: 0.75rem;
        line-height: 1rem;
      }

      &--display-name {
        color: #0a8287;
        font-size: 0.875rem;
      }
    }
  }

  &__skeleton > div {
    padding: 1rem 2.5rem 1rem 1rem;
    border-top: 1px solid #d2d3dc;

    .ry-skeleton {
      margin: 0;
    }
  }
}
