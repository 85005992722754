@import '../../variables.scss';

.manage-team-users {
  .ry-table__td {
    vertical-align: middle;
  }

  &__name-email {
    margin: -0.75rem 0;
  }

  &__edit-user {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: #0a8287;
    }

    .ry-icon {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
    }
  }

  &__deactivate {
    // reset button
    margin: 0;
    padding: 0;
    color: $red;
    font-weight: 600;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    appearance: none;
  }
}
