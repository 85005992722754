@import '~@ryan/components/src/styles/variables';

.task-overview {
  flex: 1 0 auto;
  max-width: 50%;
  margin-bottom: 3rem;

  button.ry-button {
    padding-top: 1rem;
  }

  h2,
  h3,
  h4 {
    margin: 0;
    font-weight: 600;
  }

  h2,
  h4 {
    text-transform: uppercase;
  }

  h2 {
    margin-bottom: 0.5rem;
    color: map-get($blue-steps, 700);
    font-size: 0.875rem;
    letter-spacing: 0.125rem;
  }

  h3 {
    margin-bottom: 1rem;
    font-size: 1.125rem;
  }

  h4,
  .attachments > .ry-label {
    color: #626374;
    font-size: 0.75rem;
  }

  &__actions {
    display: flex;
    margin-top: 2.5rem;
    border-top: 1px solid map-get($gray-steps, 200);

    button {
      margin-left: auto;
    }
  }
}
