@import '../../../variables';

.notification-preferences-card {
  position: relative;

  &__table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    td {
      padding: 0.25rem;
      vertical-align: top;
      border-bottom: 1px solid #d8d8d8;
    }

    // expand column
    td:nth-child(1) {
      width: 4rem;
    }

    // checkbox columns
    td:nth-child(3),
    td:nth-child(4) {
      width: 4rem;
    }

    .label {
      padding: 1rem 0;
      font-weight: bold;
    }

    .preference-row {
      font-size: 0.875rem;
      background-color: #f8f8fa;

      td:nth-child(2) {
        padding-left: 0;
        vertical-align: middle;
      }
    }
  }

  &__options {
    position: absolute;
    top: 1.25rem;
    right: 0.375rem;
    display: flex;

    .ry-icon {
      width: 2rem;
      height: 2rem;
    }

    span {
      display: block;
      font-weight: 600;
      font-size: 0.75rem;
      text-transform: uppercase;
    }

    div {
      width: 4rem;
      text-align: center;
    }
  }

  hr {
    margin-bottom: 0;
  }

  .ry-card__content {
    padding: 1rem 0;
  }

  &__update-button {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1rem 0;
  }
}
