@import '../../variables.scss';

.select-folder {
  &__selection {
    margin-bottom: 0.5rem;

    .ry-text-input {
      margin: 0;

      :focus + button {
        color: $action-teal;
      }
      button {
        color: inherit;
      }
    }

    &__folder-explorer {
      display: flex;
      flex-direction: column;
      height: 18rem;
      margin: 2px 0 0 0;
      border: 1px solid #ced5d9;
      box-shadow: 0 0 4px 0 rgba(47, 64, 71, 0.3);

      ul {
        flex: 1 1 auto;
        margin: 0;
        padding: 0;
        overflow: auto;

        li {
          position: relative;

          button {
            width: 100%;
            min-height: 3rem;
            padding: 0.75rem 3rem;
            color: inherit;
            line-height: 1.5rem;
            text-align: left;
            background: none;
            border: none;
            appearance: none;

            .ry-icon {
              position: absolute;
              top: 0.75rem;
              right: 0.75rem;
              left: auto;
              width: 1.5rem;
              height: 1.5rem;

              &:first-of-type {
                left: 0.75rem;
              }

              // &:last-of-type {
              //   right: 0.75rem;
              //   left: auto;
              // }
            }

            &:disabled {
              color: map-get($teal-steps, 300);
            }

            &:not(:disabled) {
              cursor: pointer;

              &:hover {
                color: #0a8287;
                background-color: #ebf5f5;
              }
            }

            &:focus {
              box-shadow: none;
            }
          }

          &.new-folder {
            background-color: #ffffe0;
            border-color: #f5f5b4;
            border-style: solid;
            border-width: 1px 0;
          }
        }
      }

      &__preview {
        padding: 1rem;
        text-align: center;
        overflow-wrap: break-word;
        border-top: 1px solid #ced5d9;
      }
    }
  }
}
