@use "sass:math";

// @ryan/components functions and mixins
// stylelint-disable-next-line no-invalid-position-at-import-rule
@import '~@ryan/components/src/styles/utils';

// portal-ui mixins

@mixin ry-avatar-scaled($scale) {
  $base-dimension: 2rem;
  $scaled-dimension: $base-dimension * $scale;

  .ry-avatar {
    @content;
  }

  .ry-avatar,
  .ry-avatar__fallback,
  .ry-avatar__image {
    width: $scaled-dimension;
    height: $scaled-dimension;
  }

  .ry-avatar__fallback {
    font-size: math.div($scaled-dimension, 2);
    line-height: $scaled-dimension;
  }

  .ry-avatar--inverse .ry-avatar__fallback,
  .ry-avatar--ghost .ry-avatar__fallback {
    line-height: calc(#{$scaled-dimension} - 2px); // make room for border

    .ry-icon {
      // Minus px because there is a border around the avatar
      // that makes the needed with to be 30px, not 32px
      width: calc(#{$scaled-dimension} - 2px);
      height: calc(#{$scaled-dimension} - 2px);
    }
  }
}
