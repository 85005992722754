@import '../../mixins.scss';

.project-edit-team {
  &__add-ryan-team-member {
    padding: 1rem;
    background-color: #f8f8fa;
  }

  &__user-search {
    padding-bottom: 4rem;
  }

  &__team {
    padding-left: 2rem;

    .current-team-header {
      padding-bottom: 1rem;
      padding-left: 0.5rem;
      color: #626374;
      font-weight: 600;
      font-size: 1rem;
      text-transform: uppercase;
    }

    .current-team-display {
      padding-left: 0.3rem;
    }

    .ry-table {
      .plainText {
        padding-top: 0.25rem;
      }

      .ry-checkbox {
        display: inline-block;

        &__label {
          padding-right: 0;
        }
      }

      .removeUserFromList {
        .ry-icon {
          color: #d13f4b;
        }
      }
    }

    .add-team-table-buttons {
      width: 100%;
      padding-top: 1rem;
      text-align: right;

      button {
        margin-left: 1rem;
      }
    }
  }

  &__team-user-avatar {
    margin: 0 1rem 1rem 0;

    @include ry-avatar-scaled(2);
  }
}

.add-ryan-team-member-tooltip {
  &__name {
    font-weight: 600;
    font-size: 1rem;
  }

  &__info {
    font-weight: normal;
    font-size: 0.875rem;
  }
}
