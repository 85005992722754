.custom-view {
  &-builder {
    margin-top: 1rem;
  }

  &-init {
    @media (min-width: 601px) {
      .ry-button-group {
        flex-direction: row;

        .custom-view-init__next {
          order: 3;
        }
      }
    }
  }

  &-well {
    @media (min-width: 768px) {
      position: absolute;
      top: 4.5rem;
      right: 0.5rem;
      left: 0.5rem;
    }
  }
}
