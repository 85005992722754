@import '../../../variables.scss';

.projects-timelines {
  &__header {
    margin-bottom: 1.5rem;
    text-align: center;

    @media (max-width: 767px) {
      .ry-button {
        margin: 1rem;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .ry-dropdown {
        min-width: 8rem;
      }

      &-right {
        display: flex;
      }
    }
  }

  &__filter-practice-areas {
    .ry-checkbox-group {
      max-height: 360px;
      overflow: auto;
    }
  }
}
