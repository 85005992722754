.project-snapshot-assignments {
  position: relative;
  margin-bottom: 0.5rem;
  padding: 1.125rem 1.875rem 1.125rem 1.5rem;
  text-transform: uppercase;
  border: 1px solid #e6e6e6;
  border-radius: 8px;

  .ry-button {
    position: absolute;
    top: 0.625rem;
    right: 0.875rem;
    padding-top: 0.25rem;
    text-transform: initial;
  }

  .ry-h4 {
    margin-bottom: 0.75rem;
    line-height: 1rem;
  }

  &__breakdown {
    display: flex;

    &__bar-chart {
      width: 100%;
      margin-right: 4.125rem;

      &__details {
        display: flex;
        color: #626374;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;

        span {
          display: block;
        }

        > div {
          display: flex;
          align-items: baseline;
          margin-right: 2.25rem;

          &:nth-child(2) {
            > div:first-child {
              background-color: #edae49;
            }
          }

          &:nth-child(3) {
            flex: 1 0 auto;

            > div:first-child {
              background-color: #a6caff;
            }
          }
        }

        &__marker {
          width: 0.5rem;
          height: 0.5rem;
          margin-right: 0.5rem;
          background-color: #cc2936;
          border-radius: 0.125rem;
        }
      }
    }

    &__summary {
      margin-right: 1.125rem;
      padding-right: 1.25rem;
      line-height: 0.875rem;
      border-right: 0.063rem solid #979797;

      &__title {
        color: #626374;
        font-weight: 600;
        font-size: 0.875rem;
      }

      &__total {
        display: block;
        margin-bottom: 1rem;
        color: #3e4050;
        font-size: 2rem;
        font-family: 'bebas-neue', sans-serif;
        line-height: 2.375rem;
      }
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
  }

  &__no-assignments {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    text-transform: initial;

    .ry-icon {
      margin-right: 0.75rem;
      color: #a3a4b2;
    }
  }
}
