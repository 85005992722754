.savings-summary-card-content {
  min-height: 100%;

  &__popover {
    //width of the smallest breakpoint
    width: 23.43rem;

    // @todo fix in component library?
    border-radius: 4px;

    &-target {
      position: absolute;
      top: 1rem;
      right: 0.5rem;
      color: #0a8287 !important;
    }
  }

  &__filter {
    padding: 26px 16px 26px 16px;
    @media (max-width: 375px) {
      .radio-button-group {
        margin-left: 3rem;
      }

      .col-md-6:first-child {
        padding-bottom: 1rem;
      }

      .col-md-6 {
        margin-left: 1rem;
      }
    }
  }

  &__date-range {
    font-size: 0.875rem;

    .ry-skeleton {
      width: 100%;
      max-width: 5rem;
      height: 0.875rem;
      margin-top: 0.5rem;
    }
  }

  &__loading {
    padding: 12.3rem 0;
    text-align: center;
  }

  &__error-state {
    min-height: 20rem;
    padding: 4rem 0;
    text-align: center;

    .ry-icon {
      width: 5.5rem;
      height: 5.5rem;
      color: #a3a4b2;
    }

    p {
      margin: 0 auto;
      color: #3e4050;
      font-size: 1rem;
    }
  }

  &__buttons {
    button + button {
      margin-top: 1rem;
    }
  }
}
