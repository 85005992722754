@import '../../variables';

.invoice-card {
  position: relative;

  > .ry-card {
    &__title {
      margin-bottom: 1rem;
    }
  }

  &--included {
    > .ry-card {
      &__content {
        padding-bottom: 0;
        background-color: #f8f8fa;
        box-shadow: inset 0 7px 9px -7px rgba(210, 211, 220, 0.8);
      }
    }
  }

  &__view-all {
    position: absolute;
    top: 1.25rem;
    right: 0.25rem;
  }

  &__card {
    .ry-h2 {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .ry-card__content {
      padding: 0;
    }

    &-left .labeled-list {
      padding: 1rem 0;
    }

    &-content {
      padding: 1rem;
    }

    &-amount {
      font-weight: 600;
    }

    .well {
      border-radius: 0.5rem;
    }

    &--closed {
      .well {
        background-color: #f4f8f5;
      }

      .invoice-card__card-status,
      .invoice-card__card-balance {
        color: $green;
      }
    }

    &--pastdue {
      .well {
        background-color: #fcf4f5;
      }

      .invoice-card__card-status,
      .invoice-card__card-amount {
        color: $red;
      }
    }

    &-download {
      padding: 0.25rem 0;
      text-align: center;
      border-top: 1px solid #d2d3dc;

      .ry-button {
        margin: 0;
      }
    }
  }

  .loading-spin {
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 2rem auto;
  }
}
