@import '../../../variables.scss';

.file-directory-browser {
  position: relative;

  &__container {
    display: flex;
    flex-direction: column;
    max-height: 18rem;
    margin: 2px 0 0 0;
    padding: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ced5d9;
    box-shadow: 0 0 4px 0 rgba(47, 64, 71, 0.3);
  }

  &__options {
    flex: 1 1 auto;
    margin: 0;
    padding: 0.5rem 0;
    overflow: auto;
    list-style: none;

    .ry-icon {
      position: absolute;
      top: 0.75rem;
      left: 0.75rem;
      width: 1.5rem;
      height: 1.5rem;
    }

    button {
      display: block;
      width: 100%;
      min-height: 3rem;
      padding: 0.75rem 3rem;
      color: inherit;
      line-height: 1.5rem;
      text-align: left;
      background: none;
      border: none;
      appearance: none;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &__item {
    position: relative;
    margin: 0;
    padding: 0;

    &__loading {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 0 0.5rem;
    }

    &--back {
      margin-top: -0.5rem;
      background-color: map-get($gray-steps, 50);

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    &__into {
      right: 0.75rem;
      left: auto !important;
    }

    button {
      cursor: pointer;

      &:hover {
        color: map-get($teal-steps, 600);
        background-color: #ebf5f5;
      }
    }
  }

  // ellipsis when currently in folder with long name
  input {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ry-text-input {
    margin: 0;
  }

  .ry-text-input__icon-button {
    color: inherit;
  }

  :focus + .ry-text-input__icon-button {
    color: $action-teal;
  }
}
