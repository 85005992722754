.dashboard {
  .ry-h4 {
    margin-bottom: 1rem;
  }

  .col-card {
    margin-bottom: 1rem;

    .ry-card {
      height: 100%;
      margin: 0;
    }
  }

  // This brings me great shame.
  .savings-summary-card,
  .projects-overview-card {
    display: flex;
    flex-direction: column;

    .ry-card__content,
    .savings-summary-card-content,
    .empty {
      display: flex;
      flex-direction: column;
    }

    .ry-card__content,
    .savings-summary-card-content,
    .empty,
    .savings-summary-data-viz,
    .ry-donut-chart,
    .center-icon {
      flex: 1 1 auto;
    }

    .empty {
      align-items: center;
      justify-content: center;
    }
  }
}
