.folder-tooltip {
  min-width: 10rem;
  max-width: 15rem;
  padding: 0 0 0.25rem;
  word-wrap: break-word;
  overflow-wrap: break-word;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0;
    padding: 0.75rem 1rem;

    &:nth-child(even) {
      background-color: #f8f8fa;
    }

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  label {
    display: block;
    color: #626374;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
  }
}

.folder-tooltip-target {
  padding: 0.5rem;
  vertical-align: top;
  background: none;
  border: none;
  border-radius: 4px;
  cursor: help;

  .ry-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
