.comment {
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 3.5rem;

  .ry-avatar {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
  }

  &__content {
    font-size: 0.875rem;
    line-height: 1.25rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &__meta {
    margin-bottom: 0.25rem;
  }

  // &-user {}

  &__date {
    color: #afb1bc;
  }

  &__menu {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  .ry-textarea {
    margin-top: 0.5rem;
  }

  // @todo fix in library? "sm"all?
  .ry-button-group {
    margin: 0.25rem 0 -0.25rem;

    .ry-button {
      margin: 0.25rem 0;
    }

    @media (min-width: 601px) {
      margin: 0.5rem -0.25rem 0;

      .ry-button {
        margin: 0 0.25rem;
      }
    }
  }
}
