.project-overview {
  &__details {
    position: relative;

    .ry-button {
      position: absolute;
      top: 1rem;
      right: 0.5rem;
    }

    p {
      max-width: none;
    }

    .empty {
      padding: 1rem 2rem;
    }
  }
  // align user-card heights regardless of content
  &__user-container {
    display: flex;

    .user-info-card {
      flex: auto;
    }
  }
}
