.edit-currency-modal {
  .ry-h2 {
    margin: 0;
    padding-bottom: 2.375rem;
  }

  .ry-dropdown {
    width: 14.563rem;
    margin: 0 auto 4.375rem;

    &__label {
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;

      .info-tooltip > .ry-icon {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
}
