.learning-card-skeleton {
  &__title {
    max-width: 12rem;
    height: 1.5rem;
    margin-bottom: 1rem;
  }

  &__p {
    margin-bottom: 1rem;

    .ry-skeleton {
      display: inline-block;
      width: 100%;

      &:last-child {
        width: 60%;
      }

      + .ry-skeleton {
        margin-top: 0.5rem;
      }
    }
  }
}
