.impersonate-page {
  .horizontal-form {
    display: flex;
    align-items: flex-start;

    .ry-autocomplete {
      min-width: 18rem;
    }

    .ry-button {
      margin-top: 1.25rem;
      margin-left: 1rem;
    }
  }

  section {
    margin: 2rem 0;
  }

  // Mobile
  @media (max-width: 600px) {
    .horizontal-form {
      flex-direction: column;

      .ry-autocomplete {
        width: 100%;
      }

      .ry-button {
        width: 100%;
        margin-top: 1.25rem;
        margin-left: 0;
      }
    }
  }
}
