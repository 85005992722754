// @ryan/components variables
@import '~@ryan/components/src/styles/variables';

// @ryan/components variable overrides

// portal-ui variables

$cerulean: #00beed;
$light-blue: #a6caff;
$light-gray: #e9e9e9;
$medium-blue: #0083cb;
$note-grey: map-get($gray-steps, 200) !default;
$result-gray: map-get($gray-steps, 300) !default;
$filters-grey: #f6f6f6;
$modal-grey: #9c9c9d;

$error-background-red: #fcf4f5;
$error-red: #cc2936;
