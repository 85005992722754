@import '~@ryan/components/src/styles/variables';

@import '../../../../../../variables.scss';

.data-request-status {
  width: 42%;

  .well--past-due {
    background-color: $error-background-red;

    .data-request-status__details__due-date,
    .status,
    .status__icon {
      color: $error-red;
    }
  }

  &__details {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid map-get($gray-steps, 200);

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        width: 50%;
        padding-bottom: 1rem;

        button.ry-button {
          margin: 0 0.5rem;
          padding: 0;
          font-weight: 400;
          font-size: 1rem;
          letter-spacing: normal;
          text-transform: capitalize;

          &:first-of-type {
            margin-left: 0;
          }
        }

        label {
          display: block;
          color: #626374;
          font-weight: 600;
          font-size: 0.75rem;
          line-height: 1rem;
          text-transform: uppercase;
        }

        &.data-request-status__details__completion-date {
          width: 100%;
        }
      }
    }
  }
}
