@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search-input {
  &--loading {
    .ry-text-input__icon {
      animation: spin linear 1s infinite;
    }
  }
}
