.system-autocomplete {
  &__option {
    display: flex;
    align-items: flex-start;
    white-space: normal;

    &-text {
      flex: 1 1 auto;
      margin: 0 0 0 0.5rem;
    }
  }
}
