.content-only {
  .app-header,
  .ry-global-footer {
    display: none;
  }

  &.with-app-layout {
    background-color: unset;
  }
}

.file-upload-event-wrapper {
  width: 30%;
  min-width: 500px;
  margin: auto;
}
