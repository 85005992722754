.data-request-overview-details-by-type {
  display: flex;
  gap: 1rem;

  h4 {
    margin: 0;
    color: #626374;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  &--not-empty {
    margin-bottom: 1rem;
  }

  &__date-ranges,
  &__document-images,
  &__jurisdictions,
  &__propertyTaxDocumentTypes,
  &__site-classes {
    display: flex;
    flex-direction: column;

    button {
      text-wrap: nowrap;
    }
  }

  &__epr-data {
    display: flex;
    gap: 1rem;
  }
}
