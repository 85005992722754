.account-hierarchy {
  padding-top: 0.75rem;
  padding-bottom: 1rem;

  > label {
    display: block;
    color: #626374;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
  }

  &__accounts {
    &__account {
      .ry-icon {
        position: relative;
        top: -0.125rem;
        width: 1.5rem;
        height: 1.5rem;
      }

      &:nth-child(1) {
        font-weight: 600;
      }

      @for $i from 2 through 9 {
        &:nth-child(#{$i}) {
          padding-left: ($i - 2) * 1.5rem;
        }
      }
    }
  }
}

.account-hierarchy-tooltip-target {
  display: inline;

  button {
    padding: 0;
    color: inherit;
    cursor: help;

    &:hover {
      color: inherit;
    }
  }
}
