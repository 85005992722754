.activity-modified {
  margin: 0.5rem 0 0.5rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  border-left: 2px solid #d2d3dc;

  table {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  th {
    width: 6rem;
    font-weight: 600;
  }

  th,
  td {
    padding: 0.25rem 0.25rem 0.25rem 0.75rem;
    text-align: left;
    vertical-align: top;
  }

  td {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  del {
    display: block;
    color: #626374;
    text-decoration: line-through;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0;
    padding: 0;
  }
}
