.edit-user-page {
  .well {
    margin-bottom: 2rem;

    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .labeled-list li {
    margin-bottom: 0;
  }
}
