.user-name-and-profile {
  display: flex;
  color: inherit;
  text-decoration: inherit;

  .ry-avatar {
    flex: 0 0 auto;
    margin-top: 0.125rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.5rem;
    line-height: 1.2rem;

    &__name {
      font-weight: 600;
    }
  }
}
