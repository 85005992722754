.edit-role {
  hr {
    margin: 2rem 0;
  }

  .well {
    margin-bottom: 1rem;

    li {
      margin: 0;
    }
  }

  &__actions {
    margin-top: 2rem;

    .ry-button + .ry-button {
      margin-left: 1rem;
    }
  }
}
