.files-tab__table {
  .ry-table__th:nth-child(2) button {
    padding-left: 1.2rem;
  }

  .ry-table__td__engagement-id {
    display: inline-block;
    width: 6.25rem;
    text-align: right;
  }
}
