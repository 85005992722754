@import '../../variables.scss';

.checklocks {
  position: relative;
  min-width: 3rem;
  min-height: 3rem;
  cursor: pointer;
  user-select: none;

  // the <input>
  &__control {
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
    height: 3rem;
    opacity: 0;
  }

  // the box
  &__box::before {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #f8f8fa;
    border: 1px solid #adadba;
    border-radius: 0.25rem;
    transition: border-color 100ms ease-out;
    content: '';
  }

  // the select check
  &__box .ry-icon {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    color: #adadba;
  }

  // hover
  &:hover &__box::before {
    border-color: #0a8287;
  }

  // focus
  &__control:focus + &__box::before {
    outline: 0;
    box-shadow: 0 0 0 1px white, 0 0 0 4px #b2d6e8;
  }

  // checked
  &__control:checked + &__box {
    &::before {
      background-color: #ebf5f5;
      border-color: #aac2c9;
    }
    .ry-icon {
      color: #0a8287;
    }
  }
}
