.create-data-request-modal {
  @media (max-width: 1024px) {
    .ry-modal {
      min-width: 33.33333%;
      max-width: 66.66667%;
    }
  }

  width: 640px;

  .stepper {
    justify-content: center;
    margin-bottom: 30px;
    .stepper__step--current::before,
    .stepper__step--past::before {
      background-color: #0e247e;
    }
  }

  .select-folder {
    margin-bottom: 2rem;
  }

  .dss-file-upload {
    margin-bottom: 1.5rem;
  }

  .step-title {
    margin-bottom: 38px;
    font-weight: 600;
  }

  .infoWell {
    margin-bottom: 32px;
  }

  form {
    .ry-multiselect__feedback {
      color: #cc2936;
    }
    & > .dropdown {
      position: relative;
      margin-bottom: 32px;
      .dropdown__list {
        position: absolute;
        right: 0;
        left: 0;
        z-index: 1;
      }
    }
    &.erp-form {
      & > .dropdown {
        .dropdown__selection-label__placeholder {
          visibility: hidden;
        }
      }
    }
    .ry-multiselect__container__control__option-label {
      min-height: 24px;
    }
  }
}
