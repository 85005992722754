@import '../../variables.scss';

.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;

  main {
    flex: 1 1 auto;
  }

  &__content {
    padding-top: 1rem;
    padding-bottom: 4rem;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    text-align: center;

    &--content {
      padding-top: 11.25rem;
      .ry-icon {
        width: 3rem;
        height: 3rem;
        color: #a3a4b2;
      }
      p {
        margin: 0 auto;
      }
    }
  }
}

body.with-app-layout {
  // @todo Component Library should expose scss files so
  // we can share color variables, eg $ryan-navy
  background-color: $ryan-navy;
}
