.create-role {
  .ry-text-input,
  .ry-dropdown {
    max-width: 30rem;
  }

  &__actions {
    margin-top: 2rem;

    .ry-button + .ry-button {
      margin-left: 1rem;
    }
  }

  &__na {
    padding: 2rem 1rem;
    font-style: italic;
    text-align: center;
  }
}
