@import '../../variables';

.recent-data-requests-card {
  position: relative;

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 1rem 0;

    &-view-toggle {
      flex: 1 1 auto;
    }

    .ry-text-input {
      width: 18rem;

      @media (max-width: 767px) {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    @media (max-width: 375px) {
      .ry-button {
        flex: 1 1 auto;
      }
    }
  }

  tr.pastdue td {
    background-color: #fcf4f5;

    .status,
    .status__icon,
    .duedate {
      color: $red;
    }
  }

  // @todo perhaps DataRequestCard should support a non-styled prop
  table .ry-card {
    border: none;
    box-shadow: none;

    .ry-card__content,
    .data-request-card__content {
      padding: 0;
    }
  }
}
