@import '../../variables.scss';

@mixin button-reset {
  display: block;
  width: 100%;
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;

  &:focus {
    position: relative;
    outline: none;
    box-shadow: none;
    box-shadow: 0 0 0 1px #fff, 0 0 0 4px #b2d6e8;
  }
}

.savings-summary-edit {
  &__table {
    width: 100%;
    font-size: 0.875rem;
    border-collapse: collapse;

    td {
      vertical-align: top;
    }
  }

  &__th {
    height: 3rem;
    padding: 0 2.25rem 0 0.5rem;
    color: $white;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.5rem;
    white-space: nowrap;
    text-align: left;
    text-transform: uppercase;
    vertical-align: middle;
    background-color: $ryan-gray;

    // Category (name)
    &:nth-child(1) {
      padding-left: 1rem;
    }

    // Potential, Approved, Submitted, Received
    &:nth-child(n + 2):nth-child(-n + 5) {
      text-align: right;
    }

    .info-tooltip {
      position: absolute;
      top: 0.75rem;
      right: 0.5rem;
    }

    // Summary
    &:nth-child(6) {
      text-align: right;
    }

    // Actions
    &:nth-child(7) {
      width: 3rem;
    }
  }

  &__error-icon {
    display: none;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
  }

  // tr - category
  &__category {
    td {
      border-bottom: 1px solid #ebebeb;
    }

    // td
    &-name {
      padding: 1rem 0.5rem 1rem 1rem;
    }

    // td
    &-bucket {
      padding: 0 0 0 0.5rem;

      button {
        @include button-reset;

        position: relative;
      }

      // value is either in button if editable or simple div if not
      &-uneditable,
      button {
        padding: 1rem 2.25rem 1rem 0.5rem;
        overflow: hidden;
        line-height: 1.5rem;
        white-space: nowrap;
        text-align: right;
        text-overflow: ellipsis;
      }

      // pencil icon on editable rows
      &-edit-icon {
        position: absolute;
        top: 1.05rem;
        right: 0.5rem;
        width: 1.25rem !important;
        height: 1.25rem !important;
      }

      &:hover &-edit-icon {
        color: $action-teal;
      }

      // if a field has been updated
      &--edited button {
        background-color: #fef7ed;
      }

      // if a field has an error
      &--error {
        button {
          color: $red;
          background-color: #f9e9ea;
        }

        .savings-summary-edit__error-icon {
          display: inline-block;
        }
      }
    }

    // td
    &-summary {
      padding: 1rem 2.25rem 1rem 0.5rem;
      font-weight: 600;
      text-align: right;

      &--error {
        position: relative;
        color: $red;

        .savings-summary-edit__error-icon {
          display: inline-block;
        }
      }
    }

    // td
    &-actions {
      padding: 0;

      // overflow menu
      // undo delete
      button {
        @include button-reset();

        padding: 1rem;

        .ry-icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    // tr modifier
    &--readonly {
      td {
        background-color: #f8f8fa;
      }

      .savings-summary-edit__category-bucket-edit-icon {
        display: none;
      }
    }

    &--deleted {
      text-decoration: line-through;
      background-color: #fcf3f4;
    }

    &--active &-name-current {
      font-weight: 600;
    }
  }

  // td - new category button
  &__new-category {
    padding: 0;

    button {
      @include button-reset;

      padding: 1rem;
      color: $action-teal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: left;

      &:hover {
        color: #004247;
      }

      &:active {
        color: #002125;
      }
    }

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.25rem;
    }
  }

  // tbody
  &__totals {
    border-top: 3px solid #d8d8d7;

    td {
      padding: 0;
      background-color: #f8f8fa;
    }

    // button toggle in first column
    button {
      @include button-reset();

      position: relative;
      display: block;
      padding-left: 2.5rem;
      text-align: left;

      .ry-icon {
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: 1.5rem;
        height: 1.5rem;
      }

      .savings-summary-edit__totals-total,
      .savings-summary-edit__totals-change {
        display: block;
        text-align: inherit;
      }

      .savings-summary-edit__totals-total {
        padding: 1rem 0.5rem 0.5rem 0.5rem;
        font-weight: 600;
      }

      .savings-summary-edit__totals-change {
        padding: 0 0.5rem 1rem 0.5rem;
        font-style: italic;
      }
    }
  }

  &__total,
  &__change {
    display: block;
    text-align: right;
  }

  &__total {
    position: relative;
    padding: 1rem 2.25rem 0.5rem 0.5rem;
    font-weight: 600;

    &--error {
      color: $red;

      .savings-summary-edit__error-icon {
        display: inline-block;
      }
    }

    &--summary {
      font-weight: 700;
    }
  }

  &__change {
    position: relative;
    padding: 0 2.25rem 1rem 0.5rem;
    padding-top: 0;
    font-style: italic;

    &--up {
      color: $green;
    }

    &--down {
      color: $red;
    }
    // Arrows ↑↓
    .ry-icon {
      position: absolute;
      top: 0;
      right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  // tbody
  &__totals-expanded {
    td {
      padding: 0 2.25rem 0 0.5rem;
      font-size: 0.75rem;
      line-height: 1.25rem;
      background-color: #f8f8fa;

      &:nth-child(1) {
        padding: 0 0 0 3rem;
      }

      &:nth-child(n + 2) {
        text-align: right;
      }
    }

    tr:first-child td {
      padding-top: 0.75rem;
      border-top: 1px dashed #d8d8d7;
    }

    tr:last-child td {
      padding-bottom: 0.75rem;
    }

    .info-tooltip .ry-icon {
      width: 1.25rem;
      height: 1.25rem;
      vertical-align: top;
    }
  }

  &__footer {
    margin-top: 2rem;

    textarea {
      min-height: 12rem;
    }
  }

  &__error-message {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: $red;
    font-size: 0.875rem;

    .ry-icon {
      flex: 0 0 auto;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.25rem;
    }
  }

  .file-attachments-container {
    margin-bottom: 2rem;
  }
}
