@import '../../variables.scss';

.radio-button-card {
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
  font-size: 0.875rem;

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
  }

  & + & {
    margin-left: 1rem;

    @media (max-width: 767px) {
      margin-left: 0.5rem;
    }
  }

  // using a mix of border-color and box-shadow as active/inactive have
  // different border widths and changing border width is causing flex contents
  // to shift
  &--active .radio-button-card__label {
    border-color: $ryan-navy;
    box-shadow: 0 0 0 2px $ryan-navy;

    &::after {
      background-color: $ryan-navy;
      box-shadow: 0 0 0 1px $ryan-navy;
    }

    .ry-icon {
      color: $ryan-navy;
    }
  }

  // instead of display: none, hiding radio input behind label for a11y to
  // maintain keyboard controls
  &__control {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: -1;

    &:focus + .radio-button-card__label {
      outline: 0;
      box-shadow: 0 0 0 2px $ryan-navy, 0 0 0 3px #fff, 0 0 0 6px #b2d6e8;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 12rem;
    height: 100%;
    padding: 1rem 1rem 1.5rem;
    text-align: center;
    border: 1px solid map-get($gray-steps, 300);
    border-radius: 4px;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 100%;
      padding: 1rem 1rem 1rem 1.5rem;
      text-align: left;

      .radio-button-card--with-icon & {
        padding-right: 4.5rem;
      }
    }

    &::after {
      position: absolute;
      bottom: -12px;
      left: 50%;
      width: 24px;
      height: 24px;
      background-color: #fff;
      border: 6px solid #fff;
      border-radius: 50%;
      box-shadow: 0 0 0 1px map-get($gray-steps, 300);
      transform: translateX(-50%);
      content: '';

      @media (max-width: 767px) {
        top: 1rem;
        bottom: auto;
        left: 0;
      }
    }

    &__text {
      display: block;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  img {
    width: inherit;
    max-width: 100%;
  }

  .ry-icon {
    margin: 0 auto 0.5rem;

    @media (max-width: 767px) {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
    }
  }
}
