@import '../../../variables.scss';

@mixin button-reset {
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;
}

.report-builder-with-tabs {
  &__desktop {
    display: flex;

    &__tabs {
      width: 25%;
    }

    &__data {
      width: 50%;

      &--only {
        flex: 1 0 100%;
        max-width: 50rem;
      }
    }
  }
}

.report-builder {
  max-width: 50rem;

  &__buttons {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 767px) {
      .ry-button {
        display: block;
        width: 100%;
      }
    }
  }

  &__logo-selection {
    .ry-radio-group {
      margin: 0;
    }

    @media screen and (min-width: 768px) {
      margin-left: 2rem;
    }
  }

  &__logo-wrapper {
    position: relative;
    display: flex;
    margin-top: 1rem;
    margin-right: 2rem;

    .trash {
      position: absolute;
      top: -1rem;
      right: -1.25rem;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0;
      background-color: #fff;
      border: 1px solid map-get($gray-steps, 100);
      border-radius: 2rem;
      box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
      cursor: pointer;

      .ry-icon {
        width: 38px;
        height: 38px;
        padding: 5px;
        color: $red;
      }
    }
  }

  &__cover-page {
    .ry-textarea {
      margin-bottom: 1rem;
    }

    .ry-checkbox {
      display: inline-block;
      margin: 0 0 0 -0.75rem;
    }

    .ry-checkbox__label {
      color: #626374;
      font-weight: 600;
      font-size: 0.75rem;
    }

    &__baseline-switch {
      display: flex;
      align-items: center;
      padding: 2rem 0 0.75rem 0;

      // center switch and tooltip placement
      div[aria-haspopup],
      label > .ry-switch {
        display: inline-block;
        margin-left: 1rem;
        vertical-align: middle;
      }

      div[aria-haspopup] .ry-switch {
        display: block;
      }
    }
  }

  &__date-range {
    display: flex;

    .ry-datepicker {
      margin-right: 1rem;
    }
  }

  &__audience {
    .ry-autocomplete {
      width: 50%;
    }
  }

  &__projects,
  &__historical-projects {
    &.inner-panel {
      margin-bottom: 2rem;
    }

    .info-tooltip {
      margin-left: 0.25rem;
    }

    .search-input {
      margin-bottom: 1rem;
    }

    .ry-table {
      height: 28rem;
      overflow-y: auto;

      &__th {
        // override position:sticky
        position: static !important;
        top: 0;
      }

      &__td:nth-child(4) {
        white-space: nowrap;
      }
    }

    &-loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__historical-projects {
    margin-top: -1rem;

    &-toggle {
      .ry-switch {
        position: absolute;
        top: 0.25rem;
        right: 0;
        margin-top: 0;
        margin-bottom: 0;
      }

      .title-section {
        position: relative;
        margin-bottom: 2rem;
        padding-right: 3.5rem;

        .sub-title {
          font-size: 0.875rem;
        }
      }

      label {
        font-weight: 600;
      }
    }
  }

  &__content {
    .helper-text {
      margin: 1.5rem 0;
    }

    .checkbox-panel {
      margin-bottom: 1rem;
      padding: 1rem;
      background-color: #fff;
      border: solid 1px #d2d3dc;
      border-radius: 4px;
      box-shadow: 0 2px 3px 0 rgba(210, 211, 220, 0.74);

      .ry-checkbox {
        display: inline-block;
        margin: 0 0 0 -0.5rem;

        &__label {
          font-weight: 600;
          font-size: 1rem;
          text-transform: none;
        }
      }

      p {
        margin-bottom: 0;
        font-size: 0.875rem;
        line-height: 1.5rem;
      }

      @media screen and (min-width: 420px) {
        padding-left: 3.5rem;

        .ry-checkbox {
          margin-left: -3rem;
        }
      }
    }

    .switch {
      margin: 1rem 0 1.5rem 0;

      label {
        position: relative;
        font-size: 0.875rem;
      }

      .ry-switch {
        position: absolute;
        margin-left: 1rem;
      }
    }
  }

  .ry-text-input__feedback {
    margin-top: -1.5rem;
    margin-bottom: 2rem;
    color: $red;
  }

  .ry-table__td.icon {
    width: 1.5rem;
  }

  .ry-checkbox__label {
    text-transform: uppercase;
  }

  .ry-radio-group {
    display: flex;
    flex-wrap: wrap;
  }

  &__main-buttons {
    .ry-button-group {
      margin-right: 0;
      margin-left: 0;
      padding-top: 2rem;
      border-top: 1px solid map-get($teal-steps, 400);
    }
  }

  &__nav {
    margin-bottom: 1rem;

    &__tabs {
      margin-top: 0;
      list-style-type: none;

      &--mobile {
        padding: 0;

        li {
          list-style: none;
        }
      }

      li {
        &:nth-of-type(1) {
          border-top: 1px solid map-get($gray-steps, 100);
          border-bottom: 0.5px solid map-get($gray-steps, 100);
        }

        &:nth-of-type(2) {
          border-top: 0.5px solid map-get($gray-steps, 100);
          border-bottom: 1px solid map-get($gray-steps, 100);
        }
      }

      &__tab {
        &--mobile {
          display: flex;
          align-items: center;

          &--active {
            margin-bottom: 1rem;
            color: white;
            background-color: $ryan-navy;

            svg {
              color: white;
            }
          }

          svg {
            width: 2rem;
            height: 2rem;
            margin-right: 0.5rem;
          }
        }

        &--active {
          padding-left: 1.25rem;
          color: $ryan-navy;
          border-left: 4px solid $ryan-navy;
        }

        &__button {
          @include button-reset();

          width: 100%;
          padding: 1rem;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.1rem;
          text-transform: uppercase;
          list-style: none;
          cursor: pointer;

          &--active {
            padding-left: 1.25rem;
            color: $ryan-navy;
            border-left: 4px solid $ryan-navy;

            &--mobile {
              color: white;

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }

  &__unpublished-export {
    margin-left: 1rem;

    &__text {
      margin-bottom: 1rem;
      color: $ryan-gray;
      font-weight: 600;
      font-size: 16px;
    }

    &__button-section {
      padding-top: 2rem;
      padding-right: 0.5rem;
      padding-left: 1.5rem;
      background-color: $zebra-stripe;

      &__tooltip {
        width: fit-content;
        margin-bottom: 2rem;
      }

      &__footnote {
        color: $ryan-gray;
        font-size: 12px;
      }
    }
  }
}

.ry-tooltip--hidden {
  display: none;
}

.ry-report-container__centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
