@import '../../variables.scss';

.task-table {
  &__expanded {
    &-status-info {
      padding: 1rem;
      background: #f8f8fa;
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
    }

    &-followers {
      &__separator {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
      }
    }

    &-milestone-link {
      margin-top: 0.5rem;
      font-size: 0.875rem;

      .ry-icon {
        width: 1.5rem;
        height: 1.5rem;
        color: $ryan-gray;
      }
    }

    &--pastdue &-status-info {
      background-color: #fcf4f5;

      .status,
      .status__icon {
        color: $error-red;
      }
    }

    &-separator {
      margin-bottom: 0.5rem;
    }
  }

  &__header {
    &-actions {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-left: 1rem;

      .filter {
        width: 7.5rem;
      }
    }
  }

  .ry-table__tr-group-header {
    padding: 0.5rem 3.5rem;
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  .Complete {
    color: #0e247e;
    background: #f2f4f7;
  }
}
