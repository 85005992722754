.third-party-file-access-modal {
  &__selections {
    background: white;
    border: 1px solid #ced5d9;
    border-radius: 4px;

    > div {
      width: 100%;
      border-bottom: 1px solid #ced5d9;

      .ry-checkbox {
        margin-bottom: 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
