@import '../../variables.scss';

.executive-access-control {
  margin-bottom: 1rem;

  .ry-card {
    position: relative;

    &__content,
    &__title {
      padding-right: 5rem;
    }

    &__title {
      font-size: 1.125rem;
      line-height: 1.5rem;

      &::before {
        display: none;
      }
    }

    .ry-switch {
      position: absolute;
      top: 1.5rem;
      right: 1rem;
    }
  }

  .ry-checkbox {
    display: inline-block;
    vertical-align: middle;

    // align label to left and checkbox to right
    &__control {
      right: 0;
      left: auto;
    }

    &__label {
      padding: 0.75rem 3rem 0.75rem 1rem;
      font-size: 0.75rem;

      @media (max-width: 768px) {
        font-size: 0.75rem;
      }

      &::after {
        right: 1.125rem;
        left: auto;
      }

      &::before,
      .ry-checkbox__check-icon {
        right: 0.75rem;
        left: auto;
      }

      &:empty {
        display: none;
      }
    }
  }

  .ry-table {
    padding-bottom: 1rem;
  }

  .ry-table__td {
    padding: 0.5rem;
    vertical-align: middle;
  }

  .ry-table__th:last-child {
    width: 160px;

    @media (max-width: 768px) {
      width: 150px;
    }
  }

  .ry-table__td,
  .ry-table__th {
    &:last-child {
      padding-right: 1.5rem;
    }
  }

  &__group-head {
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;

    .ry-button {
      padding: 0 1.25rem;
      color: inherit;
      font: inherit;
      font-size: 0.75rem;
      letter-spacing: normal;
      text-align: left;
    }

    .ry-table__td {
      padding: 0.2rem 0;
      background-color: map-get($gray-steps, 100);
      border-bottom-color: map-get($gray-steps, 300);
    }
  }

  &__group-item {
    .ry-table__td {
      padding: 0.25rem 0.25rem 0.25rem 2.75rem;
    }
  }
}
