@import '../../../variables.scss';

.invoice-engagement-card {
  .ry-card__title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &.closed {
    .highlight {
      background-color: #f4f8f5;

      &__text,
      &__balance {
        color: $green;
      }
    }
  }

  &.pastdue {
    .highlight {
      background-color: #fcf4f5;

      &__text,
      &__amount {
        color: $red;
      }
    }
  }

  &.outstanding {
    .highlight {
      background-color: map-get($gray-steps, 50);
    }
  }
}
