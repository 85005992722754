@import '../../../variables';

.new-password-form {
  p {
    margin-bottom: 1.5rem;
  }

  .ry-text-input__icon-button .ry-icon {
    color: $ryan-gray;
  }
}
