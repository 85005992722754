/* stylelint-disable no-invalid-position-at-import-rule */
@use 'sass:math';

@import '../../variables';

.activity {
  position: relative;
  max-width: 40.125rem; // padding + 560px from design guidelines
  min-height: 3.5rem; // so icon will never overflow
  padding: 1rem 1rem 1rem 3.125rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: left;
  background-color: #fff;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px 1px 1px 4px;
  border-radius: 4px;

  &__icon {
    position: absolute;
    top: 1rem;
    left: math.div(3.125rem - 2rem, 2);
    width: 2rem;
    height: 2rem;
  }

  &__engagement {
    color: #626374;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
  }

  &__accountName {
    color: $ryan-gray;
    font-size: 0.75rem;
    line-height: 1rem;

    hr {
      margin: 0.5rem 0;
    }
  }

  &__entity {
    font-weight: 700;
  }

  &__content {
    word-wrap: break-word;
    overflow-wrap: break-word;

    .ry-link {
      white-space: nowrap;
    }
  }

  p {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  del {
    display: block;
    color: #626374;
    text-decoration: line-through;
  }

  // show an underline under the CTA when the activity is in the form of a notification
  // and any part of the notification card is hovered
  &:hover {
    .ry-link--is-notification {
      text-decoration: underline;
    }
  }
}

@mixin activity-feed-variant($name, $color) {
  .activity--#{$name} {
    border-left-color: $color;
  }
}

@include activity-feed-variant('green', $green);
@include activity-feed-variant('red', $red);
@include activity-feed-variant('lightblue', $light-blue);
@include activity-feed-variant('blue', $ryan-navy);
@include activity-feed-variant('mediumblue', #0043a1);
@include activity-feed-variant('lime', #dee480);
@include activity-feed-variant('orange', #ff903e);
@include activity-feed-variant('lightorange', #ffe100);
@include activity-feed-variant('lightpurple', #bf8aff);
@include activity-feed-variant('darkpurple', #3d16d3);
@include activity-feed-variant('pink', #f892f7);
@include activity-feed-variant('purple', #e500f0);
@include activity-feed-variant('lightteal', #01e6aa);

.activity-inset {
  margin: 0.5rem 0 0.5rem 0.75rem;
  padding: 0 0 0 0.75rem;
  font-style: italic;
  border-left: 2px solid #d2d3dc;
}
