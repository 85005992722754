@import '../../variables';

.notification-drawer {
  &__header {
    position: relative;
    margin-top: 3.5rem;

    h2 {
      display: inline-block;
      margin-bottom: 0;
      padding-right: 0.5rem;
    }

    .ry-button {
      position: absolute;
      top: 0.5rem;
      right: 0;
      padding: 0;

      &--text {
        font-size: 0.875rem;
      }
    }
  }

  &__tooltip {
    display: inline-block;
    line-height: inherit;
    cursor: pointer;

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      color: $action-teal;
    }
  }

  .ry-drawer__body {
    background-color: #f8f8fa;
  }
}
