@import '../../variables.scss';

.dropdown {
  &__label {
    margin-bottom: 0.25rem;
    color: map-get($gray-steps, 400);
    font-weight: 600;
    font-size: 0.75rem;
    font-family: 'myriad-pro', sans-serif;
    line-height: 1rem;
    text-transform: uppercase;
  }

  &__selection-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    background-color: $white;
    border: 1px solid map-get($teal-steps, 400);
    border-radius: 4px;
    cursor: pointer;

    &__placeholder {
      color: map-get($gray-steps, 400);
    }

    &__remove-option {
      color: $red;
    }

    &__caret-button {
      width: 48px;
      min-width: auto;
      max-width: none;
      padding: 0.5rem;
      color: $checkbox--disabled__color;
      font-size: inherit;
      line-height: inherit;
      background: none;
      border: 0;
      cursor: pointer;
      appearance: none;

      &:disabled {
        cursor: default;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__list {
    max-height: 10.5rem;
    margin-top: 0;
    padding: 0;
    overflow-y: auto;
    list-style: none;
    background: $white;
    border: 1px solid $light-gray;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    &--show {
      &__option {
        button {
          width: 100%;
          height: 100%;
          padding: calc(0.75rem - 1px);
          color: $ryan-gray;
          text-align: left;
          background-color: $white;
          border: none;
          cursor: pointer;

          &:hover {
            background-color: $zebra-stripe;
          }
        }
      }
    }

    &--hide {
      display: none;
    }
  }
  .ry-autocomplete__feedback {
    color: #cc2936;
  }
}
