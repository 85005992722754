@import '../../../variables';

.invoices-page {
  // increasing specificity to override base
  &__download.ry-button.ry-button--icon-only {
    padding: 0;
  }

  .ry-table__tr-group-header {
    padding: 0.5rem 3.5rem;
    font-size: 0.75rem;
    text-transform: uppercase;

    &.PastDue {
      color: $red;
      background-color: #fcf4f5;
    }

    &.Closed {
      color: $green;
      background-color: #f4f8f5;
    }
  }

  .invoices-status {
    &.pastdue {
      color: $red;
    }

    &.closed {
      color: $green;
    }
  }

  .labeled-list {
    margin-right: 0.5rem;
    color: $ryan-gray;

    &:first-child {
      padding: 1rem 0 0 0;
    }

    li {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .highlight {
    display: table;
    border-radius: 0.5rem;

    .labeled-list {
      padding: 1rem;
    }

    &__amount {
      font-weight: bold;
    }
  }
}
