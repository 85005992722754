@import '../../../variables.scss';

@mixin button-reset {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;
}

.account-switcher-mobile {
  margin-bottom: 5rem;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__filters {
    &-buttons {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 1000;
      width: 100%;
      padding: 0.5rem;
      background-color: #fff;
      box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.25);
    }

    &-switch {
      margin-top: 0.75rem;
      padding: 0 2rem 0.75rem;
      font-size: 0.875rem;
      border-bottom: 1px solid map-get($gray-steps, 200);

      .info-tooltip {
        margin-left: 0.5rem;
      }

      .ry-switch {
        margin-left: 1rem;
        vertical-align: top;
      }
    }
  }

  &__search {
    display: flex;
    padding: 0.5rem;

    .ry-text-input {
      flex-grow: 1;
    }
  }

  &__recents {
    color: #fff;
    background-color: $action-teal;

    &__option button,
    .ry-button {
      @include button-reset;

      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
    }

    // use psuedo to align indent and item height with other options
    &__option button::before,
    .ry-button svg {
      box-sizing: content-box;
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.75rem 0.5rem;
      vertical-align: middle;
      transform: none;
    }

    &__option button {
      padding-right: 1rem;

      &::before {
        display: inline-block;
        content: '';
      }
    }

    &__toggle {
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    .ry-button--text {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      letter-spacing: 0.125rem;
      border-radius: 0;
    }
  }

  // hide with visibility instead of display: none so react-sizeme in tabs
  // component can get size and prevent flicker on render
  .ry-tabs {
    position: absolute;
    margin: 0 0.5rem 0.5rem;
    visibility: hidden;
    pointer-events: none;
  }

  &--filters-open .ry-tabs {
    position: relative;
    z-index: auto;
    visibility: visible;
    pointer-events: auto;
  }

  &__empty {
    padding: 1rem;
    font-style: italic;
  }
}

@media (max-width: 767px) {
  body.with-account-switcher-mobile {
    // hide parts of app while mobile switcher is open
    .app-layout {
      > main,
      > footer {
        display: none;
      }
    }

    .ry-global-header__tabs {
      display: none;
    }

    // remove extra buffer provided for .ry-global-header__tabs since it is
    // being hidden
    &.with-ry-global-header {
      padding-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  .account-switcher-mobile {
    display: none;
  }
}
