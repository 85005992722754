.update-savings-bucket-form {
  flex: 1 1 18rem;

  .ry-text-input {
    max-width: 13.5rem;
    margin-bottom: 1.5rem;
  }

  &__additional-options {
    .ry-icon {
      color: inherit;
    }

    &__directions {
      margin-bottom: 1.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  &__added-savings .ry-icon {
    color: #217e38;
  }

  &__directions {
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &__dropped-savings .ry-icon {
    color: #cc2936;
  }
}
