@import '../../variables.scss';

.records-modal {
  @media (max-width: 1024px) {
    .ry-modal {
      min-width: 33.33333%;
      max-width: 66.66667%;
    }
  }

  width: 640px;

  .ry-modal__close {
    &:focus {
      box-shadow: none;
    }
  }

  &__titles {
    margin-bottom: 3.5rem;
    text-align: center;

    &__dr-title {
      color: $field-control--disabled__color;
    }

    .ry-h4 {
      margin-bottom: 1rem;
    }
  }

  &__data {
    max-height: 45vh;
    margin: 1.5rem 0 1rem 0;
    overflow-y: auto;
  }

  p {
    margin-bottom: 0;
    padding: 1rem 0 1rem 0;
    text-align: left;
    border-top: 0.5px solid map-get($gray-steps, 200);
    border-bottom: 0.5px solid map-get($gray-steps, 200);

    &:first-of-type {
      border-top: 1px solid map-get($gray-steps, 200);
    }

    &:last-of-type {
      border-bottom: 1px solid map-get($gray-steps, 200);
    }
  }
}
