@import '../../../variables.scss';

.file-links-modal {
  width: 975px;
  max-width: 90vw;
  max-width: calc(100vw - 2rem);

  .ry-table__th {
    position: static !important;
  }

  .ry-table__td {
    vertical-align: bottom;
  }

  .file-links-header {
    &.row {
      position: relative;
      padding-top: 5rem;
    }

    .col-6 {
      position: absolute;
      bottom: 0;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
        margin: 0 0 1rem 0;
        font-weight: 600;
        text-align: end;
      }
    }
  }

  // Engagement row group header
  &__engagement {
    position: relative;
    padding: 0.75rem 1rem 0.75rem 1rem;
    color: #3e4050;
    font-weight: bold;
    font-size: 0.75rem;

    // white top padding
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background-color: $ryan-navy;
      content: '';
    }

    // remove white top padding on first row
    tbody tr:first-child & {
      padding: 0.75rem 1rem;

      &::after {
        display: none;
      }
    }
  }

  .not-origin {
    padding-left: 1rem;
  }

  .link-row {
    .ry-button {
      padding: 0;
    }

    &-removed {
      span {
        color: #cc2936;
      }

      .ry-table__td:not(:first-child) {
        text-decoration: line-through;
      }
    }

    &-deletelink {
      .ry-table__td {
        text-decoration: line-through;
        background-color: #fcf3f4;
      }
    }
  }

  .ry-link {
    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.25rem;
      color: $ryan-gray;
    }
  }

  .upload-origin-title {
    padding-bottom: 0.25rem;
    color: #626374;
    font-size: 12px;
    text-transform: uppercase;
  }
}
td.ry-table__tr-group-header.file-links-modal__engagement {
  padding: 0.75rem 1rem 0.75rem 1rem;
  color: #3e4050;
}
