@import '../../../variables.scss';

.custom-view-builder {
  &__filter-add {
    visibility: hidden;
  }

  &__filter-controls {
    margin-left: 4rem;

    @media (min-width: 768px) {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      min-width: 6rem;
      margin-left: 0;
    }
  }

  &__filters .ry-h2 {
    margin-top: 2.5rem;
  }

  &__preview {
    border-top: 1px solid map-get($gray-steps, 200);

    &,
    .ry-table__header {
      margin-top: 2rem;
    }

    .ry-table__header-title {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    th:first-child {
      width: 70%;
    }
  }

  .custom-view-filter {
    flex-grow: 1;

    @media (min-width: 768px) {
      margin-right: 0.25rem;
    }
  }

  .custom-view-project-picker {
    margin-top: 1rem;
  }

  fieldset {
    position: relative;
    margin: 0 0 1.5rem 0;
    padding: 0 6.25rem 0 0;
    border: none;

    @media (max-width: 767px) {
      padding-right: 0;
    }

    &:last-child {
      .custom-view-builder__filter-add {
        visibility: visible;
      }
    }
  }

  input[name='name'] {
    @media (min-width: 768px) {
      display: inline-block;
      width: 300px;
    }
  }

  .radio-button-group {
    margin-bottom: 2rem;

    @media (max-width: 767px) {
      flex-direction: column;

      .radio-button {
        margin-bottom: 1rem;
        margin-left: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .row .ry-button-group:last-child {
    margin-bottom: 0;
  }
}
