// local variables and overrides to @ryan/components variables
@import './variables';

// local mixins and overrides to @ryan/components mixins
@import './mixins';

// import @ryan/components styles
@import '~@ryan/components/src/styles';

/**
 * Global Styles
 */

html {
  scroll-behavior: smooth;

  @media (prefers-reduced-motion) {
    scroll-behavior: auto;
  }
}

html,
body {
  min-width: 320px;
}

hr {
  height: 1px;
  margin: 1rem 0;
  background-color: #d8d8d8;
  border: 0;
}

p {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

button {
  color: inherit;
}

.break-word-wrap {
  word-wrap: break-word;
}

.well {
  padding: 1rem;
  background-color: map-get($gray-steps, 50);
  border-radius: 4px;
}

.white-space-pre-line {
  white-space: pre-line;
}

.labeled-list {
  // best used with .row
  // let .row set the left and right margins
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 0.75rem;
  }

  label {
    display: block;
    color: #626374;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
  }

  .ry-skeleton {
    display: inline-block;
    width: 70%;

    // label
    &:first-child {
      width: 60%;
      height: 0.75rem;
    }
  }
}

.table-empty {
  padding: 3rem 0;
  text-align: center;

  .ry-button {
    margin-top: 2rem;
  }

  &__icon {
    color: map-get($gray-steps, 300);
  }

  &__content {
    max-width: 35rem; // same as <p>
    margin: 0 auto;
  }
}

.table-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: #00508a;
    text-decoration: underline;
  }

  &:active {
    color: #003865;
    text-decoration: underline;
  }
}

small {
  font-size: 0.75rem;
  line-height: 1rem;
}

// bold-small
.bs {
  display: block;

  b,
  small {
    display: block;
  }

  &--icon {
    position: relative;
    display: block;
    padding-left: 2.5rem;

    .ry-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &--light small {
    color: #626374;
  }
}

button.bs {
  text-align: left;
}

button.ry-link.bs--icon {
  padding-left: 2.5rem;
}

a,
button.ry-link {
  &.bs {
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    &--link {
      b {
        color: $action-teal;
        text-decoration: none;
      }
    }

    &:hover b {
      color: #00508a; // @todo var
      text-decoration: underline;
    }

    &:active b {
      color: #003865; // @todo var
      text-decoration: underline;
    }
  }
}

.ry-table td .button-margins {
  margin: -0.5rem 0;
  vertical-align: top;
}

/**
 * Skeletons
 */

.ry-skeleton {
  &.ry-number-1 {
    display: inline-block;
    width: 12rem;
    height: 2.5rem;
    margin: 0.25rem 0;
  }
}

.sk-btn-lg {
  height: 3.5rem;
}

.sk-super-title {
  max-width: 14rem;
  margin-bottom: 0.5rem;
}

.sk-title {
  max-width: 18rem;
  height: 1.5rem;
  margin-bottom: 1rem;
}

.sk-p {
  max-width: 35rem;
  margin: 0 0 1rem;

  .ry-skeleton {
    display: inline-block;
    width: 100%;

    &:last-child {
      width: 60%;
    }
  }
}

.sk-field-label {
  max-width: 8rem;
  margin-bottom: 0.5rem;
}

.sk-field {
  height: 3rem;
}

/**
 * Pill
 */

.pill {
  display: inline-block;
  height: 1.5rem;
  padding: 0 1em;
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
  text-align: center;
  text-transform: uppercase;
  vertical-align: top;
  border-radius: 1.5rem;

  &.link {
    color: $action-teal;
    line-height: calc(1.5rem - 2px);
    text-decoration: none;
    background: white;
    border: 1px solid $action-teal;
    cursor: pointer;

    &:hover {
      color: #00508a;
      border-color: #00508a;
    }

    &:active {
      color: #003865;
      border-color: #003865;
    }
  }

  &.active {
    background: rgba(10, 130, 135, 0.25);
  }

  &.inactive {
    background: rgba(74, 74, 74, 0.25);
  }
}

/**
 * Loading Spinner to be used with loading icon
 */

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-spin {
  animation: loadingSpinner linear 1s infinite;
}

/**
 * Display Helpers
 */

.d-none {
  display: none !important;
}
.d-sm-none {
  @media (min-width: 375px) {
    display: none !important;
  }
}
.d-md-none {
  @media (min-width: 768px) {
    display: none !important;
  }
}
.d-lg-none {
  @media (min-width: 1024px) {
    display: none !important;
  }
}
.d-block {
  display: block !important;
}
.d-sm-block {
  @media (min-width: 375px) {
    display: block !important;
  }
}
.d-md-block {
  @media (min-width: 768px) {
    display: block !important;
  }
}
.d-lg-block {
  @media (min-width: 1024px) {
    display: block !important;
  }
}

.center-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
  padding: 1rem 0;
}

.line-clamp {
  // line-clamp
  display: -webkit-box !important;
  max-height: 3rem;
  overflow: hidden;
  -webkit-line-clamp: 2;

  // This is required for line-clamp to work... but it's removed by our autoprefixer.
  // We need to figure out how to configure the postcss to not remove stuff.
  // https://github.com/postcss/autoprefixer/issues/776
  -webkit-box-orient: vertical;
}

/**
 * Component Library Overrides
 */

button.ry-link {
  text-align: left;
}

.ry-h4 {
  margin-bottom: 0;
}

.ry-number-1 {
  font-size: 2.75rem;
  line-height: 3rem;
}

// @todo remove once added to components library
.ry-table__header-title {
  white-space: normal;

  @media (max-width: 767px) {
    margin-bottom: 0.5rem;
    white-space: normal;
  }
}

td .ry-button--icon-only.ry-button--text {
  color: inherit;

  &:hover {
    color: $action-teal;
  }

  &:disabled {
    color: #bcc4c4;
  }
}

.ry-table__td .ry-modal,
.ry-table__td--with-expand-content {
  font-size: initial;
  line-height: 1.5rem; // html
}

#ot-sdk-btn.ot-sdk-show-settings {
  padding: 0 !important;
  color: white !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  font-family: inherit !important;
  text-transform: inherit !important;
  background-color: transparent !important;
  border: none !important;
}

#ot-sdk-btn.ot-sdk-show-settings:hover {
  font-weight: inherit !important;
  font-size: inherit !important;
  font-family: inherit !important;
  text-transform: inherit !important;
  background-color: transparent !important;
  border: none !important;
}

#ot-sdk-btn-floating .ot-floating-button__front.custom-persistent-icon {
  display: none !important;
}
