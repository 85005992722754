@import '../../variables.scss';

.empty {
  padding: 3rem 2rem;
  text-align: center;

  &__icon {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
    color: map-get($gray-steps, 300);
  }

  &__content {
    margin: 0 auto;

    @media (min-width: 1024px) {
      max-width: 66.66667%;
    }
  }

  p,
  .ry-p {
    margin-right: auto;
    margin-left: auto;
  }
}
