@import '../../variables.scss';

.file-attach {
  .ry-h3 {
    font-weight: 600;
    font-size: inherit;
    line-height: inherit;
    text-align: center;
  }

  &__existing {
    padding: 1rem;
    border: 1px solid map-get($teal-steps, 400);
    border-radius: 4px;

    .file-autocomplete + .file-directory-browser {
      margin-top: 1rem;
    }
  }
}
