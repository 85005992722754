@import '../../variables';

@mixin icon-button {
  height: 100%;
  padding: 0 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;

  .ry-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.app-header {
  &__fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
  }

  &__filler {
    height: 56px;
  }

  .headroom,
  &__sub-nav--fixed {
    z-index: 999 !important;
  }

  .headroom--unfixed {
    top: 0 !important;
  }

  &__sub-nav {
    background-color: white;
    // @todo border is only present when both account switcher and breadcrumbs are present
    border-bottom: 1px solid #d2d3dc;

    // Breadcrumbs are hidden on mobile, and AccountSwitcher moves to AppHeader.
    @media (max-width: 767px) {
      // using visibility and height to hide as this seems to play nicer with
      // `react-sizeme` - `onSize` callback was not being called once hidden
      // via `display: none`
      height: 0;
      visibility: hidden;
    }

    &--fixed {
      position: fixed;
    }

    .ry-breadcrumbs {
      padding: 1rem 0;
    }

    &-account-switcher {
      // .account-switcher-desktop is inline-block
      text-align: right;
    }
  }

  &__notifications {
    @include icon-button;
    &-icon-container {
      position: relative;
      display: block;
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        width: 0.75rem;
        height: 0.75rem;
        background-color: red;
        border-radius: 0.5rem;
        transform: translate(-50%, 50%);
        content: '';
      }
    }
    &--none &-icon-container::before {
      display: none;
    }
  }

  &__account-switcher {
    @include icon-button;

    display: none;

    &--open {
      color: $action-teal;
    }

    @media (max-width: 767px) {
      display: block;
    }
  }
}
