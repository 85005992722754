.code-notes {
  &__day-group {
    margin-bottom: 1rem;

    &-label {
      position: relative;
      margin-bottom: 1rem;

      &-text {
        padding-right: 1em;
        color: #afb1bc;
        font-weight: 600;
        font-size: 0.875rem;
        text-transform: uppercase;
        background-color: white;
      }

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        z-index: -1;
        height: 1px;
        background-color: #d8d8d8;
        content: '';
      }
    }
  }
}
