@import '../../variables';

.dr-status-summary-card {
  position: relative;

  &__tooltip {
    position: absolute;
    top: 1.4rem;
    right: 1rem;
    cursor: pointer;

    .ry-icon {
      width: 1.8rem;
      height: 1.8rem;
    }

    &:hover {
      color: $action-teal;
    }
  }

  &__buttons {
    display: flex;
    margin-top: 1rem;

    button + button {
      flex-shrink: 0;
      margin-left: 1rem;
    }
  }
}
