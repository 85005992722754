@import '../../../variables.scss';

.scheduling {
  // @todo standardize in ryan-components!
  .ry-table__tr-group-header {
    padding: 0.75rem 3.5rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.5rem;
    text-transform: uppercase;

    button {
      vertical-align: top;
    }
  }

  &__workspace-group-1 {
    background-color: #f1f4f7;

    label {
      color: #0e247e;
    }
  }

  &__workspace {
    &-connection-server {
      color: #626374;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }

  &__workspace-error {
    color: $red;

    .status__icon {
      color: $red;
    }
  }

  &__workspace-error-text {
    position: relative;
    left: -6px;
    margin-top: 0.5rem;
    font-size: 0.75rem;

    .ry-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__error-cell {
    text-align: right;
  }

  &__workspace-pending {
    color: #626374;

    .status__icon {
      color: #626374;
    }
  }

  .connection-autocomplete {
    &__server {
      font-size: 0.75rem;
    }
  }
}

.workspaceModal {
  &__source-picker {
    margin-bottom: 2.25rem;
  }

  .radio-button + .radio-button {
    margin-left: 1rem;
  }
}
