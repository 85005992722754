@import '../../variables';

.data-request-card {
  .ry-label {
    margin-top: 1.5rem;
  }

  .ry-card__content {
    padding: 0;
  }

  &__content {
    padding: 1.5rem;
  }

  &__aside {
    > * + * {
      margin-top: 1rem;
    }

    .well {
      &__status {
        display: flex;

        &__dash {
          margin: 0 0.4rem;
        }
      }
    }

    &__internal-only {
      padding: 1rem;
      background-color: $zebra-stripe;
      border-radius: 0.2rem;

      .ry-label {
        margin-top: 0;
      }
    }
  }

  &--pastdue {
    .well {
      background-color: rgba($red, 0.05);
    }

    .status,
    .status__icon,
    .duedate {
      color: $red;
    }
  }

  &__comment-mobile {
    text-align: center;

    &-border {
      padding: 0.25rem 0;
      border-top: 1px solid #d2d3dc;
    }
  }

  &__comment-tablet {
    display: none;

    hr {
      margin: 2rem 0 0 0;
    }

    &-flex {
      display: flex;
      justify-content: space-between;

      &__code-notes-button {
        display: flex;
        flex-shrink: 0;
      }

      &__action-buttons {
        display: flex;
        flex-shrink: 1;
        margin-left: auto;
      }
    }
  }

  &__follow-action-container {
    &__separator {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  @media (min-width: 768px) {
    &__comment-mobile {
      display: none;
    }

    &__comment-tablet {
      display: block;
    }
  }
}
