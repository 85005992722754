@import '../../mixins.scss';

.user-autocomplete {
  &__option {
    display: flex;
    align-items: flex-start;
    white-space: normal;

    @include ry-avatar-scaled(1.25) {
      flex: 0 0 auto;
    }

    &-text {
      flex: 1 1 auto;
      margin: 0 0 0 0.5rem;
    }

    &-role {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}
