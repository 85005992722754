.project-learnings {
  &__header {
    // under tablet
    @media (max-width: 767px) {
      .ry-button {
        margin-bottom: 0.5rem;
      }
    }

    // tablet and bigger
    @media (min-width: 768px) {
      display: flex;
      align-items: center;

      h2 {
        flex: 1 1 auto;
        margin: 0;
      }

      .search-input {
        flex: 0 1 18rem;
      }
    }
  }
}
