@import '../../variables';
@import '../../mixins';

.contract-file-edit-publish-modal {
  @include media-breakpoint-up(sm) {
    max-width: 530px;
    margin-right: auto;
    margin-left: auto;
  }

  .ry-button-group {
    margin-top: 2rem;
    margin-bottom: 0;
  }

  &__publish-description {
    margin-bottom: 2rem;
  }

  &--hidden {
    display: none;
  }
}
