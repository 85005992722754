@import '../../variables.scss';

.dss-file-upload {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  &--border {
    padding: 1rem;
    border: 1px solid map-get($teal-steps, 400);
    border-radius: 4px;
  }

  // shrink header text and padding around dropzone if border is enabled
  &--shrink {
    &.dss-file-upload--border {
      padding: 1rem 0.5rem 0.5rem;
    }

    .ry-file-upload__files {
      margin-top: 0.5rem;
    }

    .ry-h3 {
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .ry-h3 {
    text-align: center;
  }
}
