@import '../../../variables.scss';

@mixin button-reset {
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;
}

.switcher-custom-views {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    vertical-align: top;
    border-bottom: 1px solid #d2d3dc;
  }

  &__view {
    &-name {
      width: 100%;
      padding: 0;

      button {
        @include button-reset;

        display: block;
        width: 100%;
        padding: 1rem;
      }

      b {
        color: #0a8287;
      }

      div {
        display: inline-block;
      }

      .ry-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
      }

      .ry-icon[data-icon='lock'] {
        margin-left: 0.5rem;
      }
    }

    &--selected &-name {
      color: #0a8287;
    }

    &-count {
      padding: 1rem 0.75rem;
      text-align: right;
    }

    &-type {
      padding: 0;

      // hide type icon in mobile switcher view
      @media (max-width: 767px) {
        display: none;
      }

      button {
        @include button-reset;

        padding: 1rem 0.75rem;

        &:hover .ry-icon {
          color: $action-teal;
        }
      }

      .ry-icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &-actions {
      padding: 0;

      button {
        @include button-reset;

        padding: 1rem 0.75rem;

        .ry-icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0 0.5rem 1rem;

    // update empty state layout on smaller screens
    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;

      .ry-button {
        margin-left: auto;
      }
    }
  }
}
