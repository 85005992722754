.impersonating-banner {
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ry-button {
      margin: -0.5rem 0;
    }
  }
}
