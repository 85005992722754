@import '../../variables.scss';

.custom-view-filter {
  display: flex;
  align-items: flex-end;

  @media (max-width: 767px) {
    position: relative;
    display: block;
    padding-left: 4rem;
  }

  .ry-autocomplete,
  .ry-dropdown {
    margin: 0 1rem 0 0;

    &:last-child {
      flex-grow: 1;
      margin-right: 0;

      @media (max-width: 767px) {
        margin-top: 0.875rem;
      }
    }

    @media (max-width: 767px) {
      margin-bottom: 0.5rem;

      &:first-child,
      &:last-child {
        width: 100%;
      }
    }
  }

  .ry-dropdown {
    width: 20%;
    min-width: 100px;

    @media (max-width: 767px) {
      width: 50%;
    }
  }

  &__prefix {
    margin: 0 1.5rem 0.75rem 0;

    @media (max-width: 767px) {
      position: absolute;
      top: 2rem;
      left: 0;
    }

    + .ry-dropdown {
      width: 25%;
      min-width: 130px;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
