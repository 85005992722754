@import '../../variables.scss';

.role-and-permissions {
  &__role {
    display: flex;
    align-items: flex-end;
    margin-bottom: 2rem;

    .ry-dropdown,
    .ry-skeleton {
      flex: 1 0 auto;
      max-width: 24rem;
    }
  }

  &__permissions {
    margin-bottom: 1rem;
    padding-top: 1rem;

    // reset Checkbox to fit in the table
    .ry-checkbox {
      margin-top: -0.75rem;
      margin-bottom: -0.75rem;

      label {
        padding-right: 0;
      }
    }

    &-footer {
      margin-top: 1rem;
    }

    &-legend {
      width: 16rem;
    }

    &-legend-target {
      display: inline-block;
      float: right;
      margin-top: 0.75rem;
      font-size: 0.875rem;
      line-height: 1.5rem;
      vertical-align: middle;
      cursor: pointer;

      @media (max-width: 420px) {
        float: none;
        margin-top: 0;
        margin-bottom: 1rem;
      }

      .ry-icon {
        position: relative;
        top: -2px;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.25rem;
        vertical-align: middle;
      }
    }
  }

  .ry-table {
    &__td {
      p {
        margin-bottom: 0;
      }
    }

    &__tr-group-header {
      padding: 0.75rem 1rem;
      font-weight: bold;
      font-size: 0.75rem;
      text-transform: uppercase;
      background-color: map-get($gray-steps, 100);
      border-bottom-color: map-get($gray-steps, 300);
    }
  }
}
