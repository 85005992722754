.unrecognized-activity {
  &__wrapper-table {
    width: 100%;
    table-layout: fixed;

    td {
      padding: 0;
    }
  }

  &__wrapper-scroll {
    padding: 1rem;
    overflow: auto;
    background-color: #eee;
  }

  .activity__icon {
    top: 50%;
    transform: translateY(-50%);
  }

  pre {
    margin: 0;
    padding: 0;
  }
}
