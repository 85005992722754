@import '../../../variables.scss';

@mixin leftGradientFade($color) {
  background: $color;

  &::before {
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    width: 2rem;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), $color);
    content: '';
    pointer-events: none;
  }
}

.switcher-counts {
  // peek the counts component when the row is hovered
  .account-switcher-desktop .account-switcher-options__account-target:hover & {
    transform: translateX(calc(100% - 8px));
    transition: transform 150ms ease-in-out;
  }

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 1rem;
  background: map-get($gray-steps, 100);
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;

  // slide in the entire counts component when only the count component itself
  // is hovered
  .account-switcher-desktop &:hover {
    transform: translateX(0) !important;
    transition: transform 300ms ease-in-out !important;
  }

  &[data-level-color='1'] {
    background: white;
  }

  &[data-level-color='2'] {
    background: map-get($gray-steps, 50);
  }

  &__total {
    position: absolute;
    right: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 3rem;
    height: 100%;
    padding-right: 1rem;
    text-align: right;

    @include leftGradientFade(map-get($gray-steps, 100));

    &[data-level-color='1'] {
      @include leftGradientFade(white);
    }

    &[data-level-color='2'] {
      @include leftGradientFade(map-get($gray-steps, 50));
    }
  }

  &__spacer {
    width: 1.5rem;
    height: 1px;
    background: none;
  }

  &__badge {
    width: 2rem;
    margin-right: 0.5rem;
    color: $ryan-gray;
    font-size: 0.75rem;

    &--active {
      background-color: adjust-color($action-teal, $alpha: -0.8) !important;
    }

    &--inactive {
      background-color: map-get($gray-steps, 200) !important;
    }
  }
}
