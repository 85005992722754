@import '../../../../variables';

.attach-buttons-wrapper {
  .radio-button-group {
    .radio-button {
      & + .radio-button {
        margin-left: 30px;
      }
      &__label {
        padding: 0;
        color: $field-control--disabled__color;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        text-transform: none;
        border: 0;
        outline: 0;
        box-shadow: none;
        &::before {
          position: relative;
          z-index: 2;
          display: inline-block;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          margin: 12px;
          vertical-align: middle;
          border: 1px solid $ryan-navy;
          border-radius: 50%;
          cursor: pointer;
          content: '';
        }
      }
      &--active {
        &::after {
          position: absolute;
          top: 18px;
          left: 18px;
          z-index: 2;
          display: inline-block;
          width: 12px;
          height: 12px;
          background: $ryan-navy;
          border-radius: 50%;
          content: '';
        }
        .radio-button__label {
          background: transparent;
        }
      }
    }
  }
}

.manage-attachments + .ry-text-input__feedback {
  margin-top: -1.8rem;
  color: #cc2936;
}
