@import '../../variables.scss';

// @todo consolidate instances of this mixin once SCSS from ryan-components is
// imported
@mixin button-reset {
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;
}

.all-recent-activity {
  hr {
    display: none;
  }

  &__date-range {
    margin-bottom: 1rem;

    &-inputs {
      .ry-datepicker {
        margin-bottom: 1rem;
      }
    }
  }

  &__filter {
    margin-bottom: 1rem;

    &-dropdown {
      .ry-dropdown {
        &__control {
          padding-left: 1rem;
          color: white;
          font-weight: 600;
          font-size: 0.875rem;
          letter-spacing: 0.125rem;
          text-transform: uppercase;
          background-color: $ryan-navy;
          border-radius: 0;

          option {
            color: black;
            text-transform: none;
            background-color: white;

            &:checked {
              color: white;
              background-color: $ryan-navy;
            }
          }
        }

        .ry-icon {
          color: #fff;
          background-color: #0e247e;
        }
      }
    }

    &-subheading,
    &-tabs {
      display: none;
    }

    &-tab {
      @include button-reset();

      width: 100%;
      margin-bottom: -1px;
      padding: 1rem;
      font-weight: 600;
      list-style: none;
      cursor: pointer;

      &--active {
        padding-left: 1.25rem;
        color: $ryan-navy;
        border-left: 4px solid $ryan-navy;
      }
    }
  }

  &__feed {
    margin-top: 1rem;
  }

  &__beginning {
    width: 100%;
    padding-bottom: 1rem;
    color: #afb1bc;
    font-size: 0.875rem;
    font-style: italic;
    text-align: center;
  }

  &__filter-subheading {
    margin-top: 1.5rem;
    color: #0e247e;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  // medium breakpoint
  @media (min-width: 768px) {
    hr {
      display: block;
    }

    &__date-range {
      // vertically center the H2
      h2 {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 0 0 1rem;
      }

      &-inputs {
        display: flex;

        .ry-datepicker {
          margin-bottom: 0;
        }

        .ry-datepicker + .ry-datepicker {
          margin-left: 1rem;
        }
      }
    }

    &__filter {
      margin: 1rem 0 0 1rem;

      &-dropdown {
        .ry-dropdown {
          display: none;
        }
      }

      &-subheading {
        display: block;
      }

      &-tabs {
        display: block;
        max-width: 20rem;
        padding-left: 0;
        list-style-type: none;
        border-bottom: 1px solid map-get($gray-steps, 100);

        li {
          border-top: 1px solid map-get($gray-steps, 100);

          &:last-child {
            border-bottom: 1px solid map-get($gray-steps, 100);
          }
        }
      }
    }
  }

  .sk-breadcrumb {
    display: inline-block;
    width: 5rem;
  }
}
