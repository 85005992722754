@import '../../../../mixins.scss';

.project-snapshot-overview {
  position: relative;
  height: 100%;
  padding: 1.125rem 1.875rem 1.5rem 1.125rem;
  border: 1px solid #e6e6e6;
  border-radius: 8px;

  p {
    max-width: 50rem;
    font-size: 0.875rem;
    line-height: 20px;
  }

  .ry-button {
    position: absolute;
    top: 0.625rem;
    right: 0.875rem;
    text-transform: capitalize;
  }

  .ry-h4 {
    margin-bottom: 1.5rem;
  }

  &__contacts {
    display: flex;
    margin: 2rem 0 2.625rem;

    .ry-skeleton {
      width: 14.375rem;

      &:first-child {
        margin-right: 3.125rem;
      }
    }

    &__contact {
      display: flex;
      padding: 0;

      @include ry-avatar-scaled(1.815) {
        // HACK: Unused. No empty blocks allowed.
        flex: 0 0 auto;
      }

      h4 {
        margin: 0 0 0.5rem;
        font-weight: 600;
        text-transform: capitalize;
      }

      &:first-child {
        margin-right: 3.125rem;
      }

      &__title-name {
        padding-left: 1.25rem;

        a {
          color: #0a8287;
          text-decoration: initial;
        }
      }
    }
  }

  &__details {
    display: flex;
    flex-wrap: wrap;

    &__detail {
      margin-right: 2rem;
      margin-bottom: 1.125rem;

      &__title {
        color: #626374;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        text-transform: uppercase;
      }

      &__metric {
        color: #3e4050;
        font-size: 0.875rem;
        line-height: 0.9375rem;

        .ry-icon {
          width: 0.875rem;
          height: 0.875rem;
          margin-top: 0.05rem;
        }
      }
    }
  }

  &__paragraph-skeleton {
    margin: 0.5rem 0;
  }

  &__no-highlights {
    display: flex;
    align-items: center;

    .ry-icon {
      margin-right: 0.75rem;
      color: #a3a4b2;
    }
  }
}
