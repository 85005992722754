.bar-chart {
  position: relative;
  width: 100%;
  height: 0.313rem;
  margin: 0.75rem 0 1.5rem;
  overflow: hidden;
  border-radius: 0.281rem;

  div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
