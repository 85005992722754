.data-request-card--skeleton {
  .sk-cta {
    height: 3.5rem;
  }

  .sk-status {
    max-width: 5rem;
  }

  .labeled-list {
    .ry-skeleton {
      display: inline-block;
      width: 70%;
    }

    .ry-skeleton:first-child {
      width: 60%;
      height: 0.75rem;
    }
  }

  .data-request-card__comment-tablet {
    .ry-skeleton {
      flex: 0 1 7rem;
      margin: 0.75rem;
    }
  }

  .data-request-card__comment-mobile {
    .ry-skeleton {
      width: 7rem;
      margin: 0.75rem auto;
    }
  }
}
