.currency-carousel {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  > button {
    z-index: 1;
    width: fit-content;
  }

  ul {
    flex: 1 0 auto;
    max-width: calc(100% - 96px);
    margin: 0;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;

    &::before,
    &::after {
      display: inline-block;
      width: 50%;
      height: 3rem;
      vertical-align: top;
      content: '';
    }

    li {
      display: inline-block;

      button {
        width: 3rem;
        height: 3rem;
        padding: 0;
        color: inherit;
        font-weight: 400;
        font-size: 12px;
        line-height: 0.75rem;
        letter-spacing: normal;

        &::before {
          display: block;
          width: 0.5rem;
          height: 0.5rem;
          margin: 0 auto 0.3rem;
          background-color: grey;
          border-radius: 0.25rem;
          content: '';
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0) 2rem,
      rgba(255, 255, 255, 0.4) 2rem,
      rgb(255, 255, 255)
    );
    pointer-events: none;
  }
}
