.update-data-request-modal {
  @media (max-width: 1024px) {
    .ry-modal {
      min-width: 33.33333%;
      max-width: 66.66667%;
    }
  }

  width: 640px;

  &__info-well {
    margin-bottom: 2rem;
  }

  &__skeleton {
    display: grid;
    gap: 2rem;

    .ry-skeleton {
      height: 2rem;
    }
  }
}
