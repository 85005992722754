.help-page-form {
  width: 45%;
  margin: 0.5rem auto;
  padding: 2.5rem;
}

@media (max-width: 480px) {
  .help-page-form {
    min-width: 90%;
  }
}

.help-page-title {
  margin-top: 0.5rem;
  text-align: center;
}
