@import '../../../variables.scss';

.custom-view-project-picker {
  margin-bottom: 2rem;
  border-top: 1px solid map-get($gray-steps, 200);

  @media (min-width: 768px) {
    display: flex;
    align-content: center;
    align-items: stretch;
    justify-content: space-between;

    &__project-table--matching
      .custom-view-project-picker__action-btn
      .ry-icon {
      transform: rotate(-90deg);
    }

    > .empty {
      margin: auto;
    }

    .ry-table {
      display: flex;
      flex: auto;
      flex-direction: column;
      width: 45%;
    }

    .ry-table__body {
      flex-grow: 1;
      max-height: 720px;
    }
  }

  &__action-project:hover {
    color: $action-teal;
    cursor: pointer;
  }

  &__arrows {
    display: block;
    margin: 1.75rem auto;

    @media (min-width: 768px) {
      display: inline-block;
      margin: auto 1.5rem;
      transform: translateY(3rem) rotate(90deg);
    }
  }

  .ry-table__body {
    overflow-y: scroll;
    border: 1px solid map-get($gray-steps, 200);
    border-radius: 4px;

    thead tr:first-child .ry-table__th {
      top: 0;
    }
  }

  .ry-table__header {
    margin-top: 2rem;

    @media (max-width: 767px) {
      display: flex;
      align-items: center;

      .ry-table__header-actions {
        margin-left: auto;
      }

      .ry-table__header-title {
        margin-bottom: 0;
      }
    }
  }

  .ry-table__header-title {
    font-size: 1.125rem;
    line-height: 1.5rem;

    @media (max-width: 400px) {
      font-size: 0.875rem;
    }

    span {
      font-weight: 400;
    }
  }

  .ry-table__td {
    background-color: transparent;

    &:last-child {
      width: 3rem;
      padding-top: 0.25rem;
    }
  }

  tr:last-child td {
    border-bottom: none;
  }

  &__project-table {
    &--empty thead {
      display: none;
    }

    &--selected .ry-table__header {
      @media (max-width: 767px) {
        margin-top: 0;
      }
    }

    .empty {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
}
