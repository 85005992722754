@use "sass:math";

/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '../../variables';

$activity-dt-border-width: 1px;
$activity-dt-border-offset: 0.2rem;
$activity-dt-margin-right: 2rem;
$activity-margin-bottom: 1rem;

@mixin bullet {
  position: absolute;
  box-sizing: content-box;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #d2d3dc;
  border-color: white;
  border-style: solid;
  border-width: 0.5rem 0;
  border-radius: 50%;
  content: '';
}

.activity-feed {
  width: 100%;
  margin: 0;
  padding: 0;
  table-layout: fixed;
  border-collapse: collapse;

  // datetime
  &__dt {
    position: relative;
    width: 8.25rem;
    padding: 0 $activity-dt-margin-right $activity-margin-bottom 0;
    color: #626374;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: right;
    vertical-align: middle;

    @media (max-width: 480px) {
      width: 7rem;
    }

    // line
    &::before {
      position: absolute;
      top: 0;
      right: calc(
        #{math.div($activity-dt-margin-right, 2)} - #{math.div(
            $activity-dt-border-width,
            2
          )} + #{$activity-dt-border-offset}
      );
      bottom: 0;
      width: $activity-dt-border-width;
      background-color: map-get($gray-steps, 100);
      content: '';

      .activity-feed tr:last-child & {
        bottom: $activity-margin-bottom;
      }
    }

    // bullet
    &::after {
      @include bullet;

      top: calc(50% - #{math.div($activity-margin-bottom, 2)});
      right: calc(
        #{math.div($activity-dt-margin-right, 2) - 0.25rem} + #{$activity-dt-border-offset}
      );
      transform: translateY(-50%);
    }

    &-relative {
      color: map-get($teal-steps, 700);
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__activity {
    position: relative;
    padding: 0 0 $activity-margin-bottom 0;
  }

  //
  // Skelton
  //

  &--skeleton {
    .activity-feed__dt {
      .ry-skeleton {
        width: 3rem;
        height: 0.5rem;
        margin-left: auto;

        &:first-child {
          width: 5rem;
        }

        + .ry-skeleton {
          margin-top: 0.5rem;
        }
      }
    }

    .activity-feed__activity {
      .ry-skeleton:first-child {
        position: absolute;
        top: 1rem;
        left: 0.625rem;
        width: 1rem;
        height: 1rem;
      }

      .ry-skeleton:last-child {
        width: 60%;
        margin-top: 0.5rem;
      }
    }
  }
}
