.data-request-upload-modal {
  width: 900px;
  min-width: auto;
  max-width: none;

  .ry-radio-group {
    margin-bottom: 0;
  }

  .ry-textarea {
    margin-bottom: 1.5rem;

    &__control {
      height: 9rem;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .ry-message {
    margin-right: auto;
    margin-left: auto;
  }

  &__flex {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5rem;

    &__left,
    &__right {
      flex: 1;
    }

    &__left {
      max-width: 50%;

      .manage-attachments {
        padding: 1rem;
        border: 1px solid #aab9bf;
        border-radius: 0.25rem;
      }
    }

    &__right {
      max-width: 50%;
      padding-left: 1rem;
    }
  }

  .dss-file-upload {
    flex: 0 1 400px;
  }

  .manage-attachments {
    margin: 0;

    .file-attach {
      margin: 0;
    }
  }

  &__buttons {
    .ry-button + .ry-button {
      margin-left: 1rem;
    }
  }

  @media (max-width: 899px) {
    width: auto;

    &__flex {
      display: block;
    }

    .dss-file-upload {
      margin: 0 0 1rem;
    }

    &__buttons {
      text-align: center;
    }
  }
}
