.learning-card {
  .ry-card__content {
    padding: 0;
  }

  p {
    max-width: none;
  }

  .learning-files-table {
    margin-top: 1rem;
  }

  &__content {
    padding: 1rem;
  }

  .well {
    margin-top: 1rem;
  }

  &__footer {
    padding: 0.5rem;
    border-top: 1px solid #d2d3dc;
  }
}
