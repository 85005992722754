@import '../../../../variables';

.release-notes-modal__inner-wrapper .release-note {
  &__single {
    margin-bottom: 1.563rem;
    &__title {
      margin: 0 0 1.25rem;
      padding-bottom: 0.5rem;
      color: $ryan-navy;
      font-size: 0.875rem;
      line-height: 1.125rem;
      text-transform: uppercase;
      border-bottom: 0.063rem solid $note-grey;
    }
    &__details {
      max-width: none;
      margin-bottom: 0.5rem;
      color: $ryan-gray;
      text-align: left;
    }
    &__more {
      padding: 0;
      color: $action-teal;
      font-weight: 600;
      background: transparent;
      border: 0;
      cursor: pointer;
    }
  }
}
