@import '../../variables';

.data-requests-page {
  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 1rem 0;

    &-view-toggle {
      flex: 1 1 auto;
    }

    .search-input {
      width: 18rem;

      @media (max-width: 767px) {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  .ry-table__tr-group-header {
    padding: 0.5rem 3.5rem;
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  .Complete {
    color: #0e247e;
    background: #f2f4f7;
  }

  // @todo prop on component
  tr.pastdue td {
    background-color: #fcf4f5;

    .status,
    .status__icon,
    .duedate {
      color: $red;
    }
  }

  // @todo perhaps DataRequestCard should support a non-styled prop
  table .ry-card {
    border: none;
    box-shadow: none;

    .ry-card__content,
    .data-request-card__content {
      padding: 0;
    }
  }
}
