.team-page {
  .ry-table {
    &__td {
      font-size: 0.875rem;
    }

    &__tr {
      .ry-button--sm {
        margin-top: -0.5rem;
        margin-bottom: -0.5rem;
        color: inherit;
      }
    }

    &__expanded {
      span,
      .row__content {
        p {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .team-table__icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}
