.project-snapshot-savings {
  position: relative;
  padding: 1.125rem 1.125rem 1.5rem 1.5rem;
  text-transform: uppercase;
  border: 1px solid #e6e6e6;
  border-radius: 8px;

  .bar-chart {
    width: calc(100% - 1.375rem);
    margin-bottom: 0.625rem;
  }

  .ry-button {
    position: absolute;
    top: 0.625rem;
    right: 0.875rem;
    padding-top: 0.25rem;
    text-transform: initial;
  }

  .ry-h4 {
    margin-bottom: 0.625rem;
    line-height: 1rem;
  }

  &__breakdown {
    display: flex;

    &__bar-chart {
      width: 100%;

      &__details {
        display: flex;
        flex-wrap: wrap;
        line-height: 1rem;

        > div {
          margin-left: 1.375rem;
        }

        &__amount {
          display: block;
          margin-top: 0.375rem;
          margin-bottom: 0.125rem;
          color: #3e4050;
          font-size: 1.5rem;
          font-family: 'bebas-neue', sans-serif;
        }

        &__marker {
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          margin-right: 0.25rem;
          border-radius: 0.125rem;
        }

        &__percent {
          font-size: 0.75rem;
          line-height: 1.25rem;
        }

        &__title {
          color: #626374;
          font-weight: 600;
          font-size: 0.75rem;
          letter-spacing: 0.025rem;
        }
      }
    }

    &__summary {
      min-width: 9.625rem;
      margin-right: 1rem;
      color: #3e4050;
      font-size: 0.75rem;
      text-transform: initial;
      border-right: 0.063rem solid #979797;

      .ry-icon {
        width: 1.375rem;
        height: 1.375rem;
        margin-left: 0.125rem;
      }

      &__total {
        display: block;
        margin-bottom: 0.25rem;
        padding-right: 1rem;
        font-size: 2rem;
        font-family: 'bebas-neue', sans-serif;
        line-height: 2.375rem;
      }
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.625rem;
  }

  &__no-savings {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    text-transform: initial;

    .ry-icon {
      margin-right: 0.75rem;
      color: #a3a4b2;
    }
  }
}
