.team-card-skeleton {
  padding-top: 1rem;

  &__user-title {
    max-width: 10rem;
  }

  &__user-role {
    display: flex;
    margin-top: 1rem;
  }

  &__user-avatar {
    flex: 0 0 auto;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
  }

  &__user-details {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;

    .ry-skeleton {
      max-width: 7rem;

      + .ry-skeleton {
        margin-top: 0.5rem;
      }

      &:nth-child(2) {
        max-width: 9rem;
      }

      &:nth-child(3) {
        max-width: 5rem;
      }
    }
  }

  &__user-contact {
    margin: 1rem 0 0 1.5rem;

    .ry-skeleton {
      display: inline-block;
      width: 1rem;

      + .ry-skeleton {
        width: 7rem;
        margin-left: 1rem;
      }
    }
  }
}
