.comment-skeleton {
  .sk-avatar {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
  }

  .comment__day-group-label {
    .ry-skeleton {
      display: inline-block;
      min-width: 3rem;
      vertical-align: middle;
    }
  }

  .comment__meta {
    .ry-skeleton {
      display: inline-block;
      width: 4rem;

      &:first-child {
        width: 6rem;
      }
    }
  }
}
