@import '~@ryan/components/src/styles/variables';

.worklist-open {
  .ry-table__header .common-actions {
    display: inline-block;
  }

  .ry-table {
    tr.past-due-assignment td {
      background-color: #fcf4f5;

      .column-due-date,
      .column-status,
      .status__icon {
        color: #cc2936;
      }
    }

    .ry-table__td {
      .column-title span:first-child,
      .column-project span:first-child,
      .column-type span:first-child {
        display: block;
      }

      .column-title span:first-child {
        font-weight: 600;
      }

      .comment-button {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}
