.update-savings-bucket-summary {
  flex: 0 0 13.5rem;
  margin-left: 2rem;

  &__breakdown {
    overflow: hidden;
    background-color: #f8f8fa;
    border-radius: 0.25rem;

    &__current,
    &__changes,
    &__updated {
      padding: 1rem;

      > label {
        display: block;
        color: #626374;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        text-transform: uppercase;
      }
    }

    &__changes,
    &__updated {
      border-top: 0.063rem solid #d8d8d7;
    }

    &__current {
      div {
        overflow: hidden;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__changes {
      > div:first-of-type {
        overflow: hidden;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;

        .ry-icon {
          width: 1.5rem;
          height: 1.5rem;
        }

        &.negative {
          color: #cc2936;
        }

        &.positive {
          color: #217e38;
        }
      }

      &__addtional-details {
        margin-top: 1rem;
        border-top: 0.063rem dashed #d8d8d7;

        section {
          margin-top: 1rem;

          div {
            overflow: hidden;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1.25rem;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          label {
            display: block;
            font-size: 0.75rem;
            line-height: 1rem;
          }
        }
      }
    }

    &__updated {
      div {
        display: block;
        overflow: hidden;
        font-size: 1.75rem;
        font-family: 'bebas-neue', sans-serif;
        line-height: 2rem;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &--error {
        color: #cc2936;
        background-color: #f9e9ea;
        border-top-color: #cc2936;

        .ry-icon {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  &__error {
    display: flex;
    margin-top: 0.5rem;
    color: #cc2936;
    font-size: 0.75rem;
    line-height: 1rem;

    .ry-icon {
      flex: 0 0 auto;
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;
    }
  }
}
