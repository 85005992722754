@import '../../variables';

.contract-card {
  position: relative;

  &__view-all {
    position: absolute;
    top: 1.25rem;
    right: 0.25rem;
  }

  table {
    width: 100%;
    font-size: 0.875rem;
    table-layout: fixed;
    border-collapse: collapse;

    tr:nth-child(odd) {
      background-color: map-get($gray-steps, 50);
    }

    td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
    }

    td:not(:first-child) {
      width: 3rem;
      padding: 0;
    }
  }

  .tooltip-list-target {
    padding: 0.75rem;
  }

  .loading-spin {
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 2rem auto;
  }
}
