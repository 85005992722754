.ry-global-footer {
  &__content {
    @media (max-width: 575px) {
      padding: 0 1rem;
    }

    span {
      position: relative;
      top: 0.2rem;
      display: inline-block;
      height: 0.97rem;
      margin: 0 0.55rem 0 0.65rem;
      border-right: 0.1rem solid white;
    }
  }
}
