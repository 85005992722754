.engagement-autocomplete {
  &__option {
    &-account {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
  &__suboption {
    font-size: 12px;
    line-height: 16px;
  }
}
