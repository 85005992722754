.update-user-access {
  hr {
    margin: 2rem 0;
  }

  section {
    margin-bottom: 2rem;
  }

  .ry-button-group {
    justify-content: flex-end;
  }

  .ry-checkbox {
    max-width: fit-content;
  }

  @media (max-width: 375px) {
    &__deny-access-modal {
      .ry-button {
        width: 100%;
      }
    }
  }

  &__language-selector {
    .ry-dropdown {
      max-width: 23.75rem;
    }
  }
}
