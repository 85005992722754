@import '../../../variables';
@import '../../../mixins';

.ry-global-header {
  &__user-name {
    display: none;

    @include media-breakpoint-up(md-lg) {
      display: inline;
    }
  }

  &__tabs {
    > button {
      padding: 1.75rem 1.25rem;
      color: $ryan-gray;

      @include media-breakpoint-down(sm) {
        padding: 0.75rem;
        font-size: 10px;
        letter-spacing: 1.5px;
      }

      @include media-breakpoint-down(xs) {
        min-width: 48px;
        padding: 0 0.5rem;
        text-align: center;
      }
    }
  }
}
