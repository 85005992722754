.error-page {
  width: 20rem;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;

  img {
    width: 17rem;
    height: 14rem;
  }

  p {
    margin: 0 auto;
    padding-bottom: 1rem;
  }

  &__404 {
    .ry-textarea {
      margin-bottom: 0;
    }
    &--buttons {
      padding-bottom: 1rem;
    }
  }
}
