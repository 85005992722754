.user-context {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: white;
  text-align: center;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__logo {
    width: 4rem;
    height: 4rem;
    opacity: 0.05;
  }
}
