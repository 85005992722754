@import '../../variables.scss';

.data-request-content {
  .ry-h3 {
    margin-bottom: 0;
  }

  .ry-label {
    margin-top: 1.5rem;

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.2rem;
    }
  }

  .attachments {
    margin-bottom: 0;
  }

  &__data-request-type {
    margin: 0;
    font-size: 14px;
  }

  &__data-request-fields {
    display: flex;

    &__field {
      margin-right: 1rem;

      &:last-of-type {
        margin-right: 0;
      }

      &__value {
        color: $ryan-gray;
      }

      &__value-array {
        display: flex;
        flex-direction: column;

        .formatted-record {
          margin-bottom: 0;
        }
      }
    }
  }
}
