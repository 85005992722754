@import '../../../variables';

.savings-history {
  // HACK: position relative being forced by unknown functionality
  position: initial !important;

  &__overflow {
    overflow-x: auto;
  }

  &__table {
    width: 1024px;
    font-size: 0.875rem;
    border-collapse: collapse;

    @media (min-width: 1025px) {
      width: 100%;
      max-width: 100%;
    }

    &-left {
      position: relative;
      min-width: 12.5rem;
      padding: 1rem 1rem 0 0;
      line-height: 1rem;
      text-align: right;
      text-transform: uppercase;
      vertical-align: top;

      &--oneline {
        padding-top: 1.5rem;
      }

      b {
        display: block;
      }
    }

    &-content {
      padding: 0;
      vertical-align: top;
    }

    &-timeline {
      .savings-history__table-left {
        padding-right: 1.75rem;

        &::before {
          position: absolute;
          top: 2rem;
          right: 1rem;
          bottom: 0;
          z-index: 1;
          width: 0.5rem;
          height: 0.5rem;
          background-color: #d2d3dd;
          border-radius: 0.25rem;
          box-shadow: 0 0 0 0.5rem white;
          transform: translate(50%, -50%);
          content: '';
        }

        &::after {
          position: absolute;
          top: 0;
          right: 1rem;
          bottom: 0;
          width: 1px;
          background-color: #d2d3dd;
          content: '';
        }
      }

      tr:first-child .savings-history__table-left::after {
        top: 2rem;
      }

      tr:last-child .savings-history__table-left::after {
        bottom: auto;
        height: 2rem;
      }
    }
  }

  &__header {
    td {
      padding-bottom: 1rem;
      vertical-align: bottom;
    }

    .savings-history__table-left {
      padding: 0;
      padding-bottom: 2rem;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: left;
      text-transform: none;
    }

    &-form {
      display: flex;
      align-items: flex-end;

      .ry-dropdown,
      .ry-datepicker {
        width: 10rem;
        margin-right: 1rem;
      }
    }

    &-space {
      flex: 1 1 auto;
    }
  }

  &__summary {
    margin-bottom: 1rem;
    padding: 1.5rem;
    background-color: #f8f8fa;
    border-radius: 4px;

    &-table {
      width: 100%;
      font-weight: normal;
      table-layout: fixed;
      border-collapse: collapse;

      th {
        padding: 0.25rem 0;
        text-align: right;
      }

      td {
        padding: 0.125rem 0.75rem;
        text-align: right;
      }

      // labels (first column)
      td:nth-child(1) {
        text-align: left;
      }

      tr:nth-child(n + 3):nth-child(-n + 5) {
        td {
          border-top: 1px dashed #e1e1e1;
        }
      }
    }

    &-headers th {
      font-weight: 600;
    }

    &-changes {
      td {
        padding: 0 0 0.5rem 0;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    &-pill {
      display: inline-block;
      padding: 0 0.75rem 0 0.25rem;
      color: #626374;
      font-style: italic;
      line-height: 2rem;
      white-space: nowrap;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 1rem;

      .ry-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 0.25rem;
        vertical-align: top;
      }

      &--up {
        color: #217e38;
        background-color: #deece1;
      }

      &--down {
        color: #cc2936;
        background-color: #f9e9ea;
      }
    }

    &-divider {
      position: relative;
      width: 2rem;

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 1px;
        background-color: #d2d3dd;
        transform: translateX(-50%);
        content: '';
      }
    }

    &-total {
      &,
      .savings-history__summary-pill {
        font-weight: 700;
      }
    }
  }

  &__empty {
    font-size: initial;
  }

  &__entry {
    position: relative;
    margin-bottom: 1rem;
    padding: 1.5rem 1.5rem 1.5rem 4rem;
    background-color: white;
    border: 1px solid #c5cece;
    border-radius: 4px;

    &::before {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      width: 5px;

      // by default, "internal"
      background-color: #a6caff;
      border-radius: 4px 0 0 4px;
      content: '';
    }

    &--only-external::before {
      background-color: $medium-blue;
    }

    &--with-external::after {
      position: absolute;
      top: 50%;
      bottom: -1px;
      left: -1px;
      width: 5px;
      background-color: $medium-blue;
      border-radius: 0 0 0 4px;
      content: '';
    }

    &-icon {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      width: 2rem;
      height: 2rem;
    }

    &-edit {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    &-description {
      padding: 0 5rem 1rem 0;
      font-size: 1rem;
      line-height: 2rem;

      b {
        font-weight: 700;
      }
    }

    &-comment {
      margin-bottom: 2rem;
      padding: 0.25rem 0.25rem 0.25rem 1rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      border-left: 4px solid #d8d8d7;

      &-title {
        margin-bottom: 0.5rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    &-table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;

      th,
      td {
        padding: 0.125rem 0.75rem;
        font-weight: normal;
        font-size: 0.75rem;
        text-align: right;
        vertical-align: top;
      }

      th {
        font-weight: 600;
        font-size: 0.875rem;
      }

      .currency {
        display: block;
      }

      // border between categories
      tbody {
        border-bottom: 1px solid #d2d3dd;

        &:last-child {
          border-bottom-color: transparent;
        }
      }

      // up and down arrows
      // added, passed, advanced out, advanced in
      .ry-icon {
        position: relative;
        top: 0.25rem;
        width: 1rem;
        height: 1rem;
        vertical-align: baseline;
      }

      // first column
      td:nth-child(1) {
        padding-left: 1.75rem;
        text-align: left;
      }

      // added, advanced out rows striped
      tr:nth-child(2) td,
      tr:nth-child(4) td {
        background-color: #f8f8fa;
      }
    }

    &-row {
      &-totals td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &:first-child {
          padding: 0 !important;
        }
      }

      &-toggle {
        position: relative;
        width: 100%;
        padding: 0.5rem 0.75rem 0.5rem 1.75rem;
        font-size: inherit;
        line-height: inherit;
        text-align: left;
        background: none;
        border: none;
        cursor: pointer;
        appearance: none;

        .ry-icon {
          position: absolute;
          top: 0.5rem;
          left: 0;
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &-name {
        display: block;
        font-size: 0.875rem;
      }

      &-total {
        font-size: 0.875rem;
      }

      // @todo category in the totals, move to entry-table
      &-change {
        font-style: italic;

        &--up {
          color: #217e38;
        }

        &--down {
          color: #cc2936;
        }
      }
    }

    &-category {
      &--deleted {
        td {
          background-color: #f8f8fa;
        }

        // added, advanced out rows striped
        tr:nth-child(2) td,
        tr:nth-child(4) td {
          background-color: #f1f1f5;
        }

        .savings-history__entry-category-name,
        .savings-history__entry-row-total {
          text-decoration: line-through;
        }
      }

      &-previous-name,
      &-deleted {
        display: block;
        font-style: italic;
        line-height: 1.4rem;
      }

      &-deleted {
        color: #626374;
      }
    }

    &-totals {
      &-label,
      &-total {
        font-weight: 700;
        font-size: 0.875rem;
      }

      &-changes-label {
        display: block;
        font-style: italic;
      }
    }
  }

  &__kickoff {
    position: relative;
    padding: 1rem 1.5rem 1rem 4rem;
    font-size: 1rem;
    line-height: 2rem;
    background-color: white;
    border: 1px solid #c5cece;
    border-left: 5px solid #d2d3dd;
    border-radius: 4px;

    &-icon {
      position: absolute;
      top: 1rem;
      left: 1.5rem;
      width: 2rem;
      height: 2rem;
    }
  }

  /**
   * MOBILE
   */

  // Reused in summary and entries
  &__mobile-table {
    width: 100%;
    text-align: right;
    table-layout: fixed;
    border-collapse: collapse;

    td {
      padding: 0 0 1rem 0;
    }

    tr:last-child td {
      padding: 0;
    }

    hr {
      margin: 0 0 1rem 0;
    }

    .currency {
      display: block;
    }
  }

  &__mobile-label {
    position: relative;
    margin: 1rem 0;
    padding-right: 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: right;
    text-transform: uppercase;

    b {
      display: block;
    }

    &::after {
      .savings-history__mobile-kickoff &,
      .savings-history__mobile-entries & {
        position: absolute;
        top: 50%;
        right: 0;
        width: 0.5rem;
        height: 0.5rem;
        background-color: #d2d3dd;
        border-radius: 0.25rem;
        transform: translateY(-50%);
        content: '';
      }
    }
  }

  &__mobile-summary {
    margin-bottom: 2rem;

    .savings-history__mobile-table {
      font-weight: 600;
      font-size: 0.875rem;
    }

    .savings-history__summary-pill {
      margin-top: 0.5rem;
      font-size: 1rem;
    }
  }

  &__mobile-entries {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 0 0 1rem 0;
      padding: 0;
    }

    .ry-label {
      display: block;
      margin: 0;
    }

    .savings-history__entry {
      padding: 1rem 1.5rem;
    }

    .savings-history__entry-description {
      line-height: 1.5rem;
    }

    // On desktop, all bucket totals are bolded.
    // On mobile, only the grand totals are bolded.
    .savings-history__entry-totals-total {
      font-weight: 400;
      font-size: 1rem;
    }

    .savings-history__entry-row-change {
      font-size: 0.875rem;

      .ry-icon {
        width: 1rem;
        height: 1rem;
        margin-top: 0.25rem;
        vertical-align: top;
      }
    }
  }

  // On mobile, only the grand totals are bolded.
  &__mobile-total {
    .savings-history__summary-pill,
    .savings-history__entry-totals-total {
      font-weight: 700;
    }
  }

  &__mobile-kickoff {
    .savings-history__kickoff {
      padding: 1rem 1.5rem;
      line-height: 1.5rem;
    }
  }

  .attachments {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  &__footer {
    @media (min-width: 376px) {
      padding: 1.5rem 2.5rem;
    }

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: initial;
    margin: 0;
    padding: 1rem;
    font-size: 0.875rem;
    background-color: map-get($gray-steps, 50);
  }
}
